import {Grid} from "@mui/material";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import DataTableCard from "components/DataTableCard";
import BarPlotCard from "components/BarPlotCard";
import Page from 'components/Page';
import { TMenu } from 'components/SideMenu/types';
import { XPartnerPageMenu } from 'components/SideMenu/menus';
import PartnerFunnelByModel from "components/wg/PartnerFunnelByModel";
import PartnerFunnelByModeliOS from "components/wg/PartnerFunnelByModelIos";
import PartnerFunnelByModelAndroid from "components/wg/PartnerFunnelByModelAndroid";
import MultipleSelect from 'components/MultipleSelect';
import {partnersList,defaultPartnerList} from 'utils/mp';
import { useState } from "react";

type Props = {
  menu? : TMenu
}

const PartnetPageGPU = ({menu}:Props)=>{
    const [partners, setPartners] = useState<string[]>(defaultPartnerList);

    return (
        <>
        <Page menu={menu || XPartnerPageMenu()} current="Model and GPU">
         <MultipleSelect allOptions={partnersList} defaultOptions={defaultPartnerList} onChange={setPartners}/>

          <Grid container spacing={3} mb={3}>
        


            <Grid item xs={12} lg={12}>

              <BarPlotCard partner={partners}
                        url="gpu_chart"
                        title="Sessions by GPU"
                        xName="gpu"
                        yName="count" />
            </Grid>



            <Grid item  xs={12} lg={12}>

              <BarPlotCard partner={partners}
                          url="gpu_user_chart"
                          title="Unique Users by GPU"
                          xName="gpu"
                          yName="count" />
            </Grid>
            
            <Grid item  xs={12} lg={6}>

            <BarPlotCard partner={partners}
                          url="model_chart"
                          title="# Sessions by model"
                          xName="model"
                          yName="count" />
            </Grid>

          <Grid item xs={12} lg={6}>
          <BarPlotCard partner={partners}
                          url="model_user_chart"
                          title="Unique Users by model"
                          xName="model"
                          yName="count" />            
          </Grid>

          </Grid>


        </Page>
      </>
    )

}

export default PartnetPageGPU;