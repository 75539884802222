import React, {useCallback, useLayoutEffect, useRef, useState} from "react";
import clsx from "clsx";
import {Link} from "@mui/material";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
//import Router from "../../router/Router";
import app from 'app';
import UsersSessions from "../UserSessions";
import UsersTable from "./UsersTable";
import Page from 'components/Page';
import { TMenu } from 'components/SideMenu/types';
import { PartnerPageMenu } from 'components/SideMenu/menus';

import "./style.scss"



type Props = {
  partner:string
  puser?:string
  //admin?:boolean
  menu? : TMenu
}

const Users = ({partner, puser, menu}:Props)=>{

  const wrapperRef = useRef<HTMLElement>()
  const [tableHeight, setTableHeight] = useState(100)

  const setHeight = useCallback(()=>{
    if(wrapperRef.current && wrapperRef.current.offsetHeight > 0){
      setTableHeight(wrapperRef.current.offsetHeight-35)
    }
  }, [])

  const navigateBack = useCallback((e)=>{
    e.preventDefault()
    app.navigate(`/users/${partner}`)
  }, [partner])


  useLayoutEffect(()=>{
    setHeight()
    window.addEventListener("resize", setHeight)
    return ()=>{
      window.removeEventListener("resize", setHeight)
    }
  }, [])

  return (
    <div className={clsx("partner-page", "partner-page-users")}>

      <Page partner={partner} menu={menu || PartnerPageMenu(partner)} current="Users">
      
        <h3 className="page-title">

          {puser
            ? <><Link href="#" onClick={navigateBack}>
                <AssignmentIndOutlinedIcon /> Users</Link> <span className="crumb">User Sessions</span>
              </>
            : <><AssignmentIndOutlinedIcon /> Users</>
          }
        </h3>

            { !puser
              ? <UsersTable partner={partner} height={ tableHeight }  />
              : <UsersSessions puser={puser} partner={partner}  />
            }

         
      </Page>

    </div>
  )
}
export default Users;
