import React from "react";
import {Alert, AlertTitle} from "@mui/material";

import "./style.scss"


type Props = {
  error:string
}
export default ({error}:Props)=>{

  return (
    <div className="error-alert-wrapper">
      <Alert variant="filled" severity="error">
        <AlertTitle>{ error }</AlertTitle>
      </Alert>
    </div>
  )
}