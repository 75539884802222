import { IDataTableColumn } from 'react-data-table-component';
import Link from 'router/Link';
import {ip_address, col,duration, fps, session,url,referrer} from 'utils/colutils'
import DataTableCard from "../components/DataTableCard";

type Props = {
  puser : string
  title?: string
  partner?: string
  admin? : boolean
}

const UsersSessions =({puser, partner, title, admin}:Props)=>{

  const columns=() : IDataTableColumn<any>[] => {
    //const partnerMode = !admin

    return [
      admin && col("partner",80),

      {name: 'user id',    selector: 'cid', sortable: true, wrap:false},
      {name: 'session id', selector: !admin ? 'session' :  (row:any)=>session(row, partner, admin), sortable: true, wrap:true},
      admin && {name: 'user_id', selector: 'user_id', sortable: true, wrap:true},
      admin && {name: 'user_email', selector: 'user_email', sortable: true, wrap:false},
      admin && {name: 'Y',  width:"50px" , selector: (row:any)=>{
          return row.models ? <Link to={`/story/${row.session}`}>Y</Link> :""
        } },
      admin && {name: 'ip_address', selector: ip_address, sortable: true, wrap:true},

      col("os",80),      col("app",80),

      col("browser"),
      admin && {name: 'gpu', selector: 'gpu', sortable: true, wrap:true},

      admin && col("country",100),

      col("version",150),
      //{name: '#ex', selector: 'exercise_started', sortable: true, wrap:true,  width:"50px"},

      {name: 'stared', selector: 'started', sortable: true, wrap:true},
      {name: 'ended', selector: 'ended', sortable: true, wrap:true},
      {name: 'duration', selector: duration, sortable: true, wrap:true,  width:"70px"},

      admin && col("models",80),
      {name: 'FPS (min,avg,max)', selector: fps, sortable: true, wrap:true,  width:"90px"},

      {name: 'url',      selector: url, sortable: true, wrap:true},
      {name: 'referrer', selector: referrer, sortable: true, wrap:true},

    ].filter(c=>c) as IDataTableColumn<any>[]
  }

  return (
    <DataTableCard url={ `sessions?puser=${puser}` } title={title} columns={ columns() } height='full' />
  )

}


export default UsersSessions;
