import React from 'react';
import { Keypoints } from '../skeleton-lib/keypoints'
import { drawSkeleton, ColorScheme, annColorScheme } from '../skeleton-lib/skeleton-vis'
//import assert from 'assert';
import Api from 'app/api';

import DataComponent, { DataComponentState } from 'components/DataComponent';


type Props = {
    session: string
}

const cs: ColorScheme = annColorScheme

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function mkKeypoints(keypoints: any): Keypoints {
    const kp = Keypoints.fromJSON(keypoints)
    for (let point of kp.absent()) {
        kp[point] = undefined
    }
    return kp;
}

async function downloadSkeleton(session: string, from:number, to: number) {
    const response = await Api.Fetch(`data/skel?session=${session}&from=${from}&to=${to}`)
    const blob = await response.blob()
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Skeleton.json`,
    );

    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode?.removeChild(link);
}



function ExIndex(props : {idx:any[], onClick:Function, session:string }) {

    for(let i=0; i<props.idx.length; i++) {
        props.idx[i].num = i;
        props.idx[i].nextIdx = props.idx[i+1]?.idx ?? -1
    }


    return (
    <>
        <h4>Exercises:</h4>
        <div style={{color:"blue"}}>
        {
            props.idx.map((ex) => {
                //console.log(ex)
                return (<p key={ex.idx}><span onClick={()=>props.onClick(ex.idx)}>{ex.exercise} @{Math.round(ex.t)}s.</span>
                           <span onClick={()=>{downloadSkeleton(props.session,ex.idx,ex.nextIdx)}}>[o-&lt;]</span>   
                        </p>)
            })
        }
        </div>
    </>)
}

function ConsoleLog(props : {text:string}) {
    return (
    <>
        <h4>Console log:</h4>
        <div>
        <pre>{props.text}</pre>
        </div>
    </>)
}

interface SkeletonState extends DataComponentState {
    index : number
}

class Skeleton extends DataComponent<Props,SkeletonState> {
    //index: number = 0
    interval: NodeJS.Timeout | null = null
    keypoints: Keypoints | null = null
    canvasRef: HTMLCanvasElement | null = null
    txt = "Loading..."
    score: number | null = null
    count: number | null = null
    exercise = ""
    feedback = ""
    slider: HTMLInputElement | null = null;
    pause: boolean = false;
    next_fps: number = 30;
    speedFactor: number = 1;

    baseUrl: string = "data/story1"

    get url() {
        return `${this.baseUrl}?session=${this.props.session}`
    }

    async onLoad() {
        //console.log("onLoad")
        await sleep(10)
        this.setState({index:0})
        this.next_fps = 1000 / (this.data.data[this.state.index].meta.fps);
        this.runInterval();
        // console.log(this.data.idx)
    }


    runInterval = () => {
        this.tick();
        this.interval = setTimeout(this.runInterval, this.next_fps * this.speedFactor);
    }

    tick = () => {
        //console.log("tick")

        if (this.state.index + 1 < this.data.data.length) {
            if (!this.pause) {
                //this.index++
                this.setState((state:SkeletonState) => ({
                    index: state.index + 1
                }));
                  
            }
        } else {
            this.setState({index:0})
        }
        if (this.slider) {
            this.slider.value = `${this.state.index}`
        }
        const d = this.data.data[this.state.index]
        this.next_fps = 1000 / (d.meta.fps);

        this.keypoints = mkKeypoints(d.kp)
        this.txt = ` ${d.meta.model}  ${Math.round(d.meta.fps)}FPS         ${d.meta.t}s #${d.meta.n} `
        this.score = d.score
        this.count = d.count
        this.exercise = d.exercise
        this.feedback = d.feedback
        this.draw()
    }

    componentWillUnmount() {
        this.stop()
    }

    stop() {
        if (this.interval) {
            clearInterval(this.interval)
            this.interval = null
        }
    }

    draw() {
        if (!this.canvasRef) {
            return
        }
        if (!this.keypoints) {
            return
        }
        //assert(this.canvasRef)
        //assert(this.keypoints)

        const ctx = this.canvasRef.getContext("2d")
        if (ctx) {
            ctx.fillStyle = "white"
            ctx.beginPath();
            ctx.rect(0, 0, this.canvasRef.width, this.canvasRef.height);
            ctx.fill()
            drawSkeleton(ctx, this.keypoints, cs, true, 0, 0, 1)

            ctx.font = "22px Courier";
            ctx.fillStyle = "black";
            ctx.fillText(this.txt, 10, 580);



            ctx.font = "22px Courier";
            ctx.fillStyle = "green";
            ctx.fillText(this.feedback, 10, 620);

            if (this.score !== null) {
                ctx.font = "22px Courier";
                ctx.fillStyle = "blue";
                ctx.fillText(`${this.score}`, 550, 620);
            }


            ctx.font = "22px Courier";
            ctx.fillStyle = "blue";
            ctx.fillText(this.exercise, 10, 660);

            if (this.count !== null) {
                ctx.font = "22px Courier";
                ctx.fillStyle = "red";
                ctx.fillText(`${this.count}`, 550, 660);
            }


        }
    }

    handleSlider = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("handleSlider=========================")
        //this.index = Number.parseInt(event.target.value)
        this.setState({index:Number.parseInt(event.target.value)})
    }
    goTo = (idx:number) => {
        console.log("goTo===================")
        //this.index = idx
        this.setState({index:idx})

    }

    Pause = () => {
        this.pause = true;
    }

    Play = () => {
        this.pause = false;
    }

    Stop = () => {
        this.setState({index:0})
        this.pause = true;
    }

    Faster = () => {
        this.speedFactor /= 2;
    }

    Slower = () => {
        this.speedFactor *= 2;
    }

    _render() {
        //console.log(toJS(this.data?.idx))
        //console.log("_render", this.state.index)

        return (
         <div   style={{ position: "absolute", width: "100%", height: "100%" }} >
            <div id="outerDiv" style={{ position: "absolute", width: "640px", height: "680px" }} >
                <canvas ref={el => this.canvasRef = el} width="640" height="680" />

                <div className="slidecontainer">
                    <input ref={(ref) => { this.slider = ref }} type="range" min={0} max={this.data.data.length} value={this.state.index} className="slider" id="myRange"
                        onChange={this.handleSlider} list="num" />
                </div>
                <div className="control-buttons">
                    <div className="control-button" onClick={this.Stop}><img alt="stop" src="/images/stop.png" height={32} width={32} /></div>
                    <div className="control-button" onClick={this.Pause}><img alt="pause" src="/images/pause.png" height={32} width={32} /></div>
                    <div className="control-button" onClick={this.Play}><img alt="play" src="/images/play.png" height={32} width={32} /></div>

                    <div className="control-button" onClick={this.Slower}><img alt="slow down" src="/images/previous.png" height={32} width={32} /></div>
                    <div className="control-button">x{1 / this.speedFactor}</div>
                    <div className="control-button" onClick={this.Faster}><img alt="faster" src="/images/fast-forward.png" height={32} width={32} /></div>
                </div>
                
            </div>

            <div id="exlist" style={{ position: "absolute", top: 0, left: "700px"}}>
                    {this.data?.idx && <ExIndex session={this.props.session} idx={this.data.idx} onClick={this.goTo}/>  }
            </div>
            <div style={{ position: "absolute", top: 0, left: "1050px"}}>
                    <ConsoleLog text={this.data?.console_log} />    
            </div>
        </div>
        )
    }
}


//export default observer(Skeleton);
export default Skeleton;
