import React from 'react';
import DataComponent from 'components/DataComponent';
import Chart from 'react-apexcharts'
import { Card, CardContent } from "@mui/material"
import ExcelIcon from 'components/ExcelIcon';


type PctFinishedWorkoutsProps = {
    partner : string
}

/* v 2 */

class PctFinishedWorkouts extends DataComponent<PctFinishedWorkoutsProps> {
  baseUrl = "pct-finished-workouts/"

  get url() {
    return `${this.baseUrl}?partner=${this.props.partner}`
  }

  _render() {
    return (
      <Card className="page-content-card">
        <CardContent>
          <h4 className="page-content-card-title"> Percent Completed Workouts <span> as function of workout number </span>
           <ExcelIcon url={this.url+"&xl=1"} filename="Percent Completed Workouts"/> 
          </h4>
          <Chart
            options={ {
              xaxis: { categories: this.data.workout_number },
              //plotOptions: { hideOverflowingLabels: false },
            } }
            series={ [

                
                    
                        { name: "% completed workouts", data: this.data.pct_finished , type: "line" },
                    

                
                    
                        { name: " average % completed exercises", data: this.data.average_percentage , type: "line" },
                    

                
                    
                        { name: "% workouts with only one exercise", data: this.data.only_one_exercise , type: "line" },
                    

                

            ] }
            type="line"
            width="100%" height={450}
          />
        </CardContent>
      </Card>
    );
  }
}


export default PctFinishedWorkouts;
