import React from 'react';
import { Keypoints } from '../../skeleton-lib/keypoints'
import { drawSkeleton, ColorScheme, annColorScheme } from '../../skeleton-lib/skeleton-vis'
//import assert from 'assert';

import DataComponent, { DataComponentState } from 'components/DataComponent';


type Props = {
    session: string
}

const cs: ColorScheme = annColorScheme

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function mkKeypoints(keypoints: any): Keypoints {
    const kp = Keypoints.fromJSON(keypoints)
    for (let point of kp.absent()) {
        kp[point] = undefined
    }
    return kp;
}



interface SkeletonState extends DataComponentState {
    index : number
}

class Skeleton extends DataComponent<Props,SkeletonState> {
    //index: number = 0
    interval: NodeJS.Timeout | null = null
    keypoints: Keypoints | null = null
    canvasRef: HTMLCanvasElement | null = null
    txt = "Loading..."
    score: number | null = null
    count: number | null = null
    exercise = ""
    feedback = ""
    slider: HTMLInputElement | null = null;
    pause: boolean = false;
    next_fps: number = 30;
    speedFactor: number = 1;

    baseUrl: string = "story1m/"

    get url() {
        return `${this.baseUrl}?session=${this.props.session}`
    }

    async onLoad() {
        console.log("got fresh data",this.props.session)
        await sleep(10)
        this.setState({index:0})
        this.next_fps = 1000 / (this.data.data[this.state.index].meta.fps);
        this.stop()
        this.runInterval();
    }


    runInterval = () => {
        this.tick();
        this.interval = setTimeout(this.runInterval, this.next_fps * this.speedFactor);
    }

    tick = () => {
        if (this.state.index + 1 < this.data.data.length) {
            if (!this.pause) {
                this.setState((state:SkeletonState) => ({
                    index: state.index + 1
                }));
                  
            }
        } else {
            //this.setState({index:0})
            this.reload()
        }
        if (this.slider) {
            this.slider.value = `${this.state.index}`
        }
        const d = this.data.data[this.state.index]
        this.next_fps = 1000 / (d.meta.fps);

        this.keypoints = mkKeypoints(d.kp)
        this.txt = ` ${d.meta.model}  ${Math.round(d.meta.fps)}FPS         ${d.meta.t}s #${d.meta.n} `
        this.score = d.score
        this.count = d.count
        this.exercise = d.exercise
        this.feedback = d.feedback
        this.draw()
    }

    componentWillUnmount() {
        this.stop()
    }

    stop() {
        if (this.interval) {
            clearInterval(this.interval)
            this.interval = null
        }
    }

    draw() {
        if (!this.canvasRef) {
            return
        }
        if (!this.keypoints) {
            return
        }

        //assert(this.canvasRef)
        //assert(this.keypoints)

        const ctx = this.canvasRef.getContext("2d")
        if (ctx) {
            ctx.fillStyle = "white"
            ctx.beginPath();
            ctx.rect(0, 0, this.canvasRef.width, this.canvasRef.height);
            ctx.fill()
            drawSkeleton(ctx, this.keypoints, cs, true, 0, 0, 1)

            ctx.font = "22px Courier";
            ctx.fillStyle = "black";
            ctx.fillText(this.txt, 10, 580);



            ctx.font = "22px Courier";
            ctx.fillStyle = "green";
            ctx.fillText(this.feedback, 10, 620);

            if (this.score !== null) {
                ctx.font = "22px Courier";
                ctx.fillStyle = "blue";
                ctx.fillText(`${this.score}`, 550, 620);
            }


            ctx.font = "22px Courier";
            ctx.fillStyle = "blue";
            ctx.fillText(this.exercise, 10, 660);

            if (this.count !== null) {
                ctx.font = "22px Courier";
                ctx.fillStyle = "red";
                ctx.fillText(`${this.count}`, 550, 660);
            }


        }
    }

    handleSlider = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({index:Number.parseInt(event.target.value)})
    }
    goTo = (idx:number) => {
        this.setState({index:idx})

    }

    Pause = () => {
        this.pause = true;
    }

    Play = () => {
        this.pause = false;
    }

    Stop = () => {
        this.setState({index:0})
        this.pause = true;
    }

    Faster = () => {
        this.speedFactor /= 2;
    }

    Slower = () => {
        this.speedFactor *= 2;
    }

    _render() {
        return (
         <div style={{transform: "scale(0.5) translate(-120px,-300px)" }}>
            <canvas ref={el => this.canvasRef = el} width="640" height="680" />
        </div>
        )
    }
}


//export default observer(Skeleton);
export default Skeleton;
