import React from 'react';
import DataComponent from 'components/DataComponent';
import Chart from 'react-apexcharts'
import { Card, CardContent } from "@mui/material"
import ExcelIcon from 'components/ExcelIcon';


type FinishedWorkoutsProps = {
    partner : string
}

/* v 2 */

class FinishedWorkouts extends DataComponent<FinishedWorkoutsProps> {
  baseUrl = "finished-workouts/"

  get url() {
    return `${this.baseUrl}?partner=${this.props.partner}`
  }

  _render() {
    return (
      <Card className="page-content-card">
        <CardContent>
          <h4 className="page-content-card-title"> Finished Workouts <span>  </span>
           <ExcelIcon url={this.url+"&xl=1"} filename="Finished Workouts"/> 
          </h4>
          <Chart
            options={ {
              xaxis: { categories: this.data.date },
              //plotOptions: { hideOverflowingLabels: false },
            } }
            series={ [

                
                    
                        { name: "total # workouts", data: this.data.workouts , type: "line" },
                    

                
                    
                        { name: "# workouts with at least 50% completed exercises", data: this.data.half_workouts , type: "line" },
                    

                
                    
                        { name: "% comleted workouts", data: this.data.pct_finished , type: "area" },
                    

                
                    
                        { name: "# comleted workouts", data: this.data.finished_workouts , type: "line" },
                    

                
                    
                        { name: "# workouts with only 1 completed exercise", data: this.data.ex1 , type: "line" },
                    

                

            ] }
            type="line"
            width="100%" height={450}
          />
        </CardContent>
      </Card>
    );
  }
}


export default FinishedWorkouts;
