import React from 'react';
import {Box, Button, Checkbox, FormControlLabel, TextField} from "@mui/material";
import app from 'app'
import {ip_address, col,duration, session,user_id, cid, user_email} from 'utils/colutils'
import Link from 'router/Link';
import DataTableCard from "components/DataTableCard";
import { MainMenu } from 'components/SideMenu/menus';
import Page from "components/Page";

export interface SearchQuery {
  url?:string
  user_id? : string
  cid? : string
  partner? : string
  os? : string
  app? : string
  model? : string
  ip_address? : string
  email? : string
  referrer? : string 
  from? : string
  to? : string
  no_dev? : boolean
  page?:string
}


export
class SessionsSearchForm extends React.Component<SearchQuery> {
    emailRef: HTMLInputElement | null = null;
    useridRef: HTMLInputElement | null = null;
    cidRef: HTMLInputElement | null = null;
    ipRef: HTMLInputElement | null = null;

    partnerRef: HTMLInputElement | null = null;
    osRef: HTMLInputElement | null = null;
    appRef: HTMLInputElement | null = null;

    modelRef: HTMLInputElement | null = null;
    refferrerRef: HTMLInputElement | null = null;

    fromRef: HTMLInputElement | null = null;
    toRef: HTMLInputElement | null = null;
    noDev: HTMLInputElement | null = null;

    search = () => {
            //console.log("***",this.noDev?.checked)
            app.navigate("sessions",{
                    email : this.emailRef?.value,
                    user_id : this.useridRef?.value,
                    cid : this.cidRef?.value,
                    ip_address : this.ipRef?.value,
                    

                    partner : this.partnerRef?.value,
                    os : this.osRef?.value,
                    app : this.appRef?.value,

                    model : this.modelRef?.value,
                    referrer : this.refferrerRef?.value,

                    from : this.fromRef?.value,
                    to : this.toRef?.value,
                    no_dev: this.noDev?.checked,
            })
    }

    onKeyDown = (event:React.KeyboardEvent) => {
        if (event.key==="Enter"){
            this.search()
        }
    }

    render() {
        return (
          <Box justifyContent='flex-end' alignItems='flex-start' sx={{display:'flex',gap:'4px',flexWrap:'wrap'}}>
            <TextField inputRef={ref=>this.emailRef=ref} defaultValue={this.props.email} onKeyDown={this.onKeyDown} autoComplete='off' label="Email" variant="outlined" size="small" sx={{maxWidth:160}} />
            <TextField inputRef={ref=>this.useridRef=ref} defaultValue={this.props.user_id} onKeyDown={this.onKeyDown} autoComplete='off' label="User ID" variant="outlined" size="small" sx={{maxWidth:130}} />
            <TextField inputRef={ref=>this.cidRef=ref} defaultValue={this.props.cid} onKeyDown={this.onKeyDown} autoComplete='off' label="cid" variant="outlined" size="small" sx={{maxWidth:130}} />
            <TextField inputRef={ref=>this.ipRef=ref} defaultValue={this.props.ip_address} onKeyDown={this.onKeyDown} autoComplete='off' label="IP address" variant="outlined" size="small" sx={{maxWidth:130}} />


            <TextField inputRef={ref=>this.partnerRef=ref} defaultValue={this.props.partner} onKeyDown={this.onKeyDown} autoComplete='off' label="Partner" variant="outlined" size="small" sx={{maxWidth:100}} />

            <TextField inputRef={ref=>this.osRef=ref} defaultValue={this.props.os} onKeyDown={this.onKeyDown} autoComplete='off' label="OS" variant="outlined" size="small" sx={{maxWidth:100}} />
            <TextField inputRef={ref=>this.appRef=ref} defaultValue={this.props.app} onKeyDown={this.onKeyDown} autoComplete='off' label="app" variant="outlined" size="small" sx={{maxWidth:100}} />
            <TextField inputRef={ref=>this.modelRef=ref} defaultValue={this.props.model} onKeyDown={this.onKeyDown} autoComplete='off' label="model" variant="outlined" size="small" sx={{maxWidth:90}} />
            <TextField inputRef={ref=>this.refferrerRef=ref} defaultValue={this.props.referrer} onKeyDown={this.onKeyDown} autoComplete='off' label="url/referrer" variant="outlined" size="small" sx={{maxWidth:100}} />

            <TextField inputRef={ref=>this.fromRef=ref} defaultValue={this.props.from} onKeyDown={this.onKeyDown} autoComplete='off' label="From" helperText={'YYYY-MM-DD'} sx={{maxWidth:120}} variant="outlined" size="small" />
            <TextField inputRef={ref=>this.toRef=ref} defaultValue={this.props.to} onKeyDown={this.onKeyDown} autoComplete='off' label="To" helperText={'YYYY-MM-DD'} sx={{maxWidth:120}} variant="outlined" size="small" />
            <FormControlLabel control={<Checkbox inputRef={ref=>this.noDev=ref} defaultChecked={this.props.no_dev} />} label="Hide dev sessions" sx={{maxWidth:120}} />
            <Button onClick={this.search} variant={'contained'} disableElevation sx={{marginTop:'5px'}}>FIND</Button>
          </Box>
        )
    }
}


const COLUMNS = [
    {name: 'session', selector: (row:any)=>session(row), sortable: true, wrap:true, width:"140px"},
    {name: 'user_id', selector: user_id, sortable: true, wrap:true, width:"140px"},
    {name: 'user',    selector: cid, sortable: true, wrap:true, width:"240px"},
    {name: 'ip_address', selector: ip_address, sortable: true, wrap:true, width:"130px"},
    {name: 'email', selector: user_email, sortable: true, wrap:false,width:"200px"},


    {name: '#ex.', selector: 'exercise_started', sortable: true, width:"50px"},
    {name: 'duration', selector: duration, sortable: true, width:"80px"},

    {name: 'Y', width:"50px",
       selector: (row:any)=>{
        return row.models ? <Link to={`/story/${row.session}`}>Y</Link> :""
    } },

    col("partner",80),
    col("app",80),

    col("os",80),
    col("browser",160),
    col("country",120),
    col("version",80),

    col("models"),
    col("min_fps",40),
    col("max_fps",40),
    col("referrer",200),

    col("gpu"),

    {name: 'stared', selector: 'started1', sortable: true, wrap:true, width:"140px"},
    {name: 'ended', selector: 'ended1', sortable: true, wrap:true, width:"140px"},
]

const SessionsSearchResults =(props:SearchQuery)=>{
  const {url, ip_address,user_id,cid,partner,os,app,model,referrer,email,from,to,no_dev, page} = props;
  const dataUrl = url
                  ? url
                  : `allsessions?x${ip_address ? `&ip_address=${ip_address}` : ``}`+
                  `${user_id ? `&user_id=${user_id}` : ``}`+
                  `${cid ? `&cid=${cid}` : ``}`+
                  `${email ? `&email=${email}` : ``}`+
                    `${from ? `&from=${from}` : ``}`+
                    `${to ? `&to=${to}` : ``}`+
                    `${partner ? `&partner=${partner}` : ``}`+
                    `${os ? `&os=${os}` : ``}`+
                    `${app ? `&app=${app}` : ``}`+
                    `${model ? `&model=${model}` : ``}`+
                    `${referrer ? `&referrer=${referrer}` : ``}`+
                    `${no_dev ? `&no_dev=1` : ``}`

  return page
      ? <>
          <Page menu={MainMenu} current={ page }>
            <h3 className="page-title">{ page }</h3>
            <DataTableCard url={ dataUrl } columns={COLUMNS} height='full' />
          </Page>
        </>
      : <DataTableCard url={ dataUrl } columns={COLUMNS} height='full' heightShift={200} />

}


function Sessions(props:SearchQuery) {
    const propsx = {...props}
    if (propsx.no_dev===undefined){
        propsx.no_dev=true
    }
    //console.log(props)
    return (
          <Page menu={MainMenu} current="Sessions" menuCollapsed>

            <Box justifyContent='space-between' alignItems="baseline" mb={2} sx={{display:'flex'}}>
              <h3 className="page-title">Sessions</h3>
              <SessionsSearchForm    {...propsx} />
            </Box>

            <SessionsSearchResults {...propsx} />

          </Page>
    )
}


export default Sessions;
export {SessionsSearchResults}
