
import api from "./api"
import {Router} from "router/Router";
import {defineRoutes} from "./pages";

import logger from '@kemtai/logger'
import config from '../config'
import { createAuth0Client, Auth0Client, User } from '@auth0/auth0-spa-js';
import {jwtVerify,createRemoteJWKSet} from 'jose'
//import {expressJwtSecret} from 'jwks-rsa'

export type UserClass = ""|"unloggedin"|"unvalidated"|"unassigned"|"kemtai"|"vori"|"red"|"green"

async function getPermitinsFromToken(jwt:string)  {
    const secretURL = `https://${config.auth0.domain}/.well-known/jwks.json`
    const JWKS = await createRemoteJWKSet(new URL(secretURL))
    const { payload } = await jwtVerify(jwt , JWKS)
    return payload.permissions as string[]
}

function stringInArray(s:string,a:string[]) : boolean {
    return Boolean(a.find( x => x===s ))
}

async function getUserClass(jwt:string) {

    const permissions = await getPermitinsFromToken(jwt)
    //console.log(permissions)

    if (stringInArray('kemtai', permissions)){
        return 'kemtai'
    }

    if (stringInArray('vori', permissions)){
        return 'vori'
    }

    return "unassigned"
}

type StateSetter = (state:any)=>void;


export class AppController {
    isAuthenticated = false
    router : Router
    pathname:string = ""
    auth0? : Auth0Client
    user? : User
    token? : string
    userClass : UserClass = "unloggedin"
    setState:StateSetter|null = null

    constructor(){
        this.router = new Router()
        window.onpopstate = (event: any) => {
            this.navigate(event.state.path, event.state.props, false, true)
        }

        defineRoutes(this.router)
        
        this.setUserClass("unloggedin")
    }
    
    setSetState(setState:StateSetter){
        this.setState = setState
    }

    setUserClass(userClass : UserClass){
        this.userClass = userClass
        this.router.cls = userClass
    }

    onLogin(token : string, email:string) {
      console.log("onLogin************************", email)
      logger.setContext("user_email",email)
      logger.event("login")

      if (token) {
        api.token = token
        this.isAuthenticated = true
        //console.log("onlogin....")
        //this.navigate(this.pathname)
      }  
    }
  
    async checkLogin() {
        //console.log("checkLogin...")

        if (this.isAuthenticated) {
            return true
        }


        if (!this.auth0) {
            return false
        }

        try {
           this.user = await this.auth0.getUser();

            if (this.user?.email_verified === false) {
                this.setUserClass("unvalidated")
                return true
            }            


            this.token = await this.auth0.getTokenSilently({authorizationParams:{ audience: config.auth0.audience}})
            this.setUserClass(await getUserClass(this.token))
            this.onLogin(this.token, this.user!.email!)
        } catch (e) {
            //console.log(e)
            return false
        }

        return Boolean(this.token)
    }
  
    async login() {

        if (!this.auth0) {
            return false
        }
        try {
            await this.auth0.loginWithPopup()
        } catch {
            console.log("loginWithPopup failed")
        }

        const ok = await this.checkLogin()
        if (ok) {
            this.navigate(this.pathname)
        }

    }


    logout(){
        if (!this.auth0) {
            return false
        }

        this.isAuthenticated = false
        this.user = undefined
        this.token = undefined
        this.setUserClass("unloggedin")
        this.auth0.logout({logoutParams:{returnTo:window.location.origin}})    
        this.navigate("/login")
    }
  
    async init() {
        this.pathname = window.location.pathname

        this.setUserClass("unloggedin")
        this.navigate("/loading")

        
        logger.init({app:"analytics",  url:"https://v1.api.kemtai.com/log/"})
        //console.log(window.location.pathname)

        this.auth0 = await createAuth0Client({
            domain: config.auth0.domain,
            clientId: config.auth0.clientId,
            authorizationParams: {
                redirect_uri: window.location.origin,
                audience : config.auth0.audience
            },
        });
    
        await this.checkLogin()
        this.navigate(this.pathname)

    }

    get Component() {
        return this.router.component
    }

    navigate(path: string, props: any = {},external: boolean = false, back: boolean = false) {
        console.log(">>>>>>>>>",path)
        this.router.navigate(path,props,external,back)
        if (this.setState) {
            this.setState({Component:this.Component})
        }
    }

}


const app = new AppController()
//app.init()
export default app;