import React from 'react';
import DataComponent from "components/DataComponent";
import Story from './Story';
import Link from 'router/Link';

type Props = {
    session : string
}


class SessionSummary extends DataComponent<Props> {
    baseUrl: string = "session_summary/"
    get url() {
        return `${this.baseUrl}?session=${this.props.session}`
    }

    render() {
        if (!this.data){
            return (
                <table>
                    <tbody><tr> 
                    <td>{this.props.session}</td>
                    </tr></tbody>
                </table>
            )
               
        }
        return (
            <p>Session <Link to={`/session/${this.props.session}`}>[{this.props.session}]</Link>
                       <Link to={`/story/${this.props.session}`}>[Y]</Link>
                {this.data.user_email &&  <><br/>{this.data.user_email}</>}
                <br/>{this.data.started} - {this.data.ended} ({Math.round(this.data.duration)}s.) 
            </p>
        )
    }

}




class ActiveStory extends DataComponent<Props> {
    _url: string = "active_sessions/"

    render(){
        return (
            <div>
            <SessionSummary session={this.props.session}></SessionSummary>
            <div style={{height:"400px", width:"600px"}}> <Story session={this.props.session}></Story> </div>
            </div>)
    }

}


export default ActiveStory;
