import React from "react";
import Chart from "react-apexcharts";
import DataComponent from "components/DataComponent";
import PageCard from "components/PageCard";


type HistogramProps = {
  partner: string
}

class MobileSessionsGraph extends DataComponent<HistogramProps> {
  baseUrl = "data/p/mobile_sessions"

  get url() {
    return `${this.baseUrl}?partner=${this.props.partner}`
  }

  _render() {
    return (
        <PageCard title="Users by device type" subtitle="last 30 days" xlurl={this.url+"&xl=1"}>
          <Chart
            options={{
              xaxis: { categories: this.data.date },
              //plotOptions: { hideOverflowingLabels: false },
            }}
            series={[
              { name: "# users with desktop devices", data: this.data.desktop },
              { name: "# users with mobile  devices", data: this.data.mobile },
              { name: "# other/unknown", data: this.data.other },
            ]}
            type="line"
            width="100%" height={450}
          />
        </PageCard>

    );
  }
}

export default MobileSessionsGraph

