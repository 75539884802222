import React from 'react';
import DataTableCard from 'components/DataTableCard';
import { UrlParamsT } from 'utils';


type TreatmentDurationProps = {
    partner : string,
    aux_url_params?: UrlParamsT
}

function TreatmentDuration(props:TreatmentDurationProps) {
    return (
        <DataTableCard url="treatment-duration/" partner={props.partner} aux_url_params={props.aux_url_params}
         title="Treatment Duration" subtitle="# days between first and last workout" 
         />
    )
}

export default TreatmentDuration;
