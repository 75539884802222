import DataTableCard from 'components/DataTableCard';
import { MainMenu } from 'components/SideMenu/menus';
import Page from "components/Page";
import { col, duration, fieldSort, session } from 'utils/colutils';
import Link from 'router/Link';


const DemoUsers = ()=>{


  return (
      <>
      <Page menu={MainMenu} current="Demo Site Visitors">
        <h3 className="page-title">Demo Site Visitors</h3>
        <DataTableCard url="demo-users/" partner="" height='full' 
          columns={[col("email"),col("country"),   
          {name: 'duration', selector: duration, sortable: true, width:"80px"},
          ,col("models"),
          
          {name: 'Y', width:"50px",
          selector: (row:any)=>{
                return row.models ? <Link to={`/story/${row.session}`}>Y</Link> :""
          }
        },

          col("last_time"),
          {name: 'session', selector: (row:any)=>session(row), sortFunction:fieldSort("session"), sortable: true, wrap:true},
   
        ]}
        />
      </Page>
    </>
  )
  
}

export default DemoUsers;
  