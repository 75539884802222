import React from 'react';
import Funnel from 'components/Funnel';
import Page from 'components/Page';
import { PartnerPageMenu } from 'components/SideMenu/menus';
import { TMenu } from 'components/SideMenu/types';

export type FunnelPageProps = {
    type?: string
    partner: string
    startDate?: Date
    endDate?: Date
    menu? : TMenu
}
  

export function FunnelPage(props:FunnelPageProps) {
    console.log("FunnelPage",props)
    return (
        <Page partner={props.partner} menu={props.menu || PartnerPageMenu(props.partner)} current="Funnel" menuCollapsed>
           <Funnel partner={props.partner} type={props.type} startDate={props.startDate}/>
        </Page>
    )
}



export function LattusFunnelPage(props:FunnelPageProps) {
    return (
        <Page partner={props.partner} menu={props.menu || PartnerPageMenu(props.partner)} current="Funnel" menuCollapsed>
           <Funnel baseUrl="player_funnel" partner={props.partner} type={props.type} startDate={props.startDate} title="Assessment Funnel" />
           <Funnel partner={props.partner} type={props.type} startDate={props.startDate} title="User Funnel" />
        </Page>
    )
}