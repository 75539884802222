import React from 'react';
import DataComponent from 'components/DataComponent';
import Chart from 'react-apexcharts'
import PageCard from 'components/PageCard';


type NewPatientsByMonthProps = {
    partner : string
}


class NewPatientsByMonth extends DataComponent<NewPatientsByMonthProps> {
  baseUrl = "new-patients-by-month/"

  get url() {
    return `${this.baseUrl}?partner=${this.props.partner}`
  }

  _render() {
    return (
      <PageCard title="New patients by month" subtitle="">
          <Chart
            options={ {
              xaxis: { categories: this.data.month },
              //plotOptions: { hideOverflowingLabels: false },
            } }
            series={ [

                

                      { name: "new_patients", data: this.data.new_patients },

                

            ] }
            type="bar"
            width="100%" height={450}
          />
      </PageCard>
    );
  }
}


export default NewPatientsByMonth;
