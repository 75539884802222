import DataTableCard from 'components/DataTableCard';
import { MainMenu } from 'components/SideMenu/menus';
import Page from "components/Page";
import StatusIndicator from 'components/wg/StatusIndicator';
import { Monitor } from '@mui/icons-material';
import MonitoringSatistics from 'components/wg/MonitoringSatistics';


export default ()=>{


  return (
      <>
      <Page menu={MainMenu} current="Monitoring">
        <StatusIndicator/>

        <MonitoringSatistics/>

      </Page>
    </>
  )

}