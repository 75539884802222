import BaseFunnel from 'components/Funnel';
 

class PartnerFunnelByModelAndroid extends BaseFunnel {
    baseUrl = "partner-funnel-by-model-android/"
    funnel_type: string = "partner"
    _title = "Android : Funnel by model "
}


export default PartnerFunnelByModelAndroid;
