import React from 'react';
import DataComponent from 'components/DataComponent';
import Chart from 'react-apexcharts'
import { Card, CardContent } from "@mui/material"
import ExcelIcon from 'components/ExcelIcon';

type FirstDaySetsDistributionByOsProps = {
    partner : string
    start? : string
    end? : string
}


class FirstDaySetsDistributionByOs extends DataComponent<FirstDaySetsDistributionByOsProps> {
  baseUrl = "first-day-sets-distribution-by-os/"

  get url() {
    let Url = `${this.baseUrl}?partner=${this.props.partner}`
    if (this.props.start) {
      Url += `&start=${this.props.start}`
    }
    if (this.props.end) {
      Url += `&end=${this.props.end}`
    }
    console.log(Url)
    return Url
  }

  _render() {
    let subtitle = ""
    if (this.props.start) {
          subtitle += ` from ${this.props.start} `
    }
    if (this.props.end) {
        subtitle += ` to ${this.props?.end} `
    }

    return (
      <Card>
        <CardContent>
          <h4 className="partner-page-card-title">Distribution of number of exercises performed by users at their first day, separated by OS and app<span>{subtitle}</span>
          <ExcelIcon url={this.url+"&xl=1"} filename="Distribution of number of exercises performed by users at their first day"/> 
          </h4>
          <Chart
            options={ {
              xaxis: { categories: this.data.date },
              // plotOptions: { hideOverflowingLabels: false },
            } }
            series={ [

                

                      { name: "windows", data: this.data.windows },

                

                      { name: "macos", data: this.data.macos },

                

                      { name: "iosapp", data: this.data.iosapp },

                

                      { name: "iosbrowser", data: this.data.iosbrowser },

                

                      { name: "androidapp", data: this.data.androidapp },

                

                      { name: "androidbrowser", data: this.data.androidbrowser },

                

            ] }
            type="line"
            width="100%" height={450}
          />
        </CardContent>
      </Card>
    );
  }
}


export default FirstDaySetsDistributionByOs;
