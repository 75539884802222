import React from 'react';
import DataComponent from 'components/DataComponent';
import Chart from 'react-apexcharts'
import PageCard from 'components/PageCard';
import strftime from 'strftime';


type WeekWorkoutUsersPlotProps = {
    partner : string
    start? : Date
    end? : Date
    periodSubtitle? : string
}

/* v2 */

class WeekWorkoutUsersPlot extends DataComponent<WeekWorkoutUsersPlotProps> {
  baseUrl = "week-workout-users-plot/"

  get url() {

    let res = `${this.baseUrl}?partner=${this.props.partner}`
    if (this.props.start) {
        const startDate = strftime("%Y-%m-%d", this.props.start)
        res += `&start=${startDate}`
    }
    if (this.props.end) {
        const endDate = strftime("%Y-%m-%d", this.props.end)
        res += `&end=${endDate}`
    }
    return res
  }

  _render() {

    let actualSubtitle =  " wpw = workouts per week"
    if (this.props.start || this.props.end) {
        actualSubtitle += " | " + this.props.periodSubtitle??""
    }

    if (this.props?.start) {
          actualSubtitle += ` from ${this.props.start} `
    }
    if (this.props?.end) {
        actualSubtitle += ` till ${this.props?.end} `
    }


    return (
      <PageCard title="Workouts Per Week" subtitle={actualSubtitle} xlurl={this.url+"&xl=1"}>
          <Chart
            options={ {
              xaxis: { categories: this.data.workouts ,
                labels: {
                    formatter:  (val:string) => val + " wpw"
                  }
              },
              //plotOptions: { hideOverflowingLabels: false },
              chart: {
                type: 'bar',
                stacked: true,
                
  

              },

            } }
            series={ [

                

                      { name: "week ≥10", data: this.data.week10 },

                

                      { name: "week 9", data: this.data.week9 },

                

                      { name: "week 8", data: this.data.week8 },

                

                      { name: "week 9", data: this.data.week9 },

                

                      { name: "week 6", data: this.data.week6 },

                

                      { name: "week 5", data: this.data.week5 },

                

                      { name: "week 4", data: this.data.week4 },

                

                      { name: "week 3", data: this.data.week3 },

                

                      { name: "week 2", data: this.data.week2 },

                

                      { name: "week 1", data: this.data.week1 },

                

            ] }
            type="bar"
            stacked={true}
            width="100%" height={450}
          />
      </PageCard>
    );
  }
}


export default WeekWorkoutUsersPlot;
