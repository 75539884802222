import React from 'react';
import Link from 'router/Link';
import Tippy from '@tippyjs/react';


export function col(c:string, width?:number, name?:string) {
    return {name: name || c, selector: c, sortable: true, wrap:true, width : width ? `${width}px` : undefined}
}

export function duration(row: any) {
    const d = Math.round(row.duration)
    if (d<60) {
        return `${d}s`
    } else {
        const dm = Math.round(d/60)
        if (dm<60) {
            return `${dm}m`
        } else {
            const dh = Math.round(dm/60)
            return `${dh}h`

        }

    }
}

export function tip(a:string,b:string) {
    return   <Tippy content={a}><span>{b}</span></Tippy>
}


export function url(row: any) {
    const v = row.url
    return v ? tip(v,v.substring(8, 30)+"...") : ""
}

export function referrer(row: any) {
    const v = row.referrer
    return v ? tip(v,v.substring(8, 30)+"...") : ""
}

export function fps(row: any) {
    return `${row.min_fps||""} ${row.fps_median||""} ${row.max_fps||""}`
}

export function session(row: any, partner:string='', admin:boolean=false) {
    const session = row.session
    return <Link to={`/session/${session}`} props={{partner, admin}}>{session}</Link>
}

export function user_id(row: any) {
    const user_id = row.user_id
    return <Link to={`/sessions/`} props={{user_id}}>{user_id}</Link> 
}
export function user_email(row: any) {
    const user_email = row.user_email
    return <Link to={`/sessions/`} props={{email:user_email}}>{user_email}</Link> 
}

export function cid(row: any) {
    const cid = row.cid
    return <Link to={`/sessions/`} props={{cid}}>{cid}</Link> 
}


export function ip_address(row: any) {
    const ip_address = row.ip_address
    if (ip_address === '212.199.32.154') {
        return `${ip_address} (Kemtai Office)`
    }
    return <Link to={`/sessions/`} props={{ip_address}}>{ip_address}</Link> 
    
}



export function fieldSort(field:string){
    return (rowA:any, rowB:any) => {
        const a = rowA[field];
        const b = rowB[field];
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    }
};
export const userSort = fieldSort('cid')
export const durationSort = fieldSort('duration')
