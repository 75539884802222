import {useState, useEffect, useReducer} from 'react';
import Api from 'app/api';


const reducer = (state:any, action:any) => {
  switch (action.type) {
    case 'get':
      return { ...state, loading: true }
    case 'success':
      return {
        ...state,
        data: action.payload.data.data,
        error: null,
        loading: false,
      }
    case 'error':
      return {
        ...state,
        data: null,
        error: action.payload.error.toString(),
        loading: false,
      }
    default:
      return state
  }
}


const useDataLoader = (url:string = '', params:any = {}) => {

	//const isCancelled = useRef(false);

  const [nonce, setNonce] = useState(Date.now())
	const [state, dispatch] = useReducer(reducer, {
    data: null,
    error: null,
    loading: true,
  })

  useEffect(() => {
    let cancel = false

    dispatch({ type: 'get' })
    Api.Get(url, params)
      .then(data => {
        !cancel && dispatch({ type: 'success', payload: { data } })
      })
      .catch(error => {
        !cancel && dispatch({ type: 'error', payload: { error } })
      })

    return () => {
      cancel = true
    }
  }, [url, nonce])

  return { ...state }

}

export default useDataLoader