import React, {useCallback, useLayoutEffect, useRef, useState} from "react";
import clsx from "clsx";
import UsersTableB2C from "./UsersTableB2C";
import Page from 'components/Page';
import { TMenu } from 'components/SideMenu/types';
import { PartnerPageMenu } from 'components/SideMenu/menus';

import "./style.scss"



type Props = {
  partner:string
  menu? : TMenu
}

export default ({partner, menu}:Props)=>{

  const wrapperRef = useRef<HTMLElement>()
  const [tableHeight, setTableHeight] = useState(100)

  const setHeight = useCallback(()=>{
    if(wrapperRef.current && wrapperRef.current.offsetHeight > 0){
      setTableHeight(wrapperRef.current.offsetHeight-35)
    }
  }, [])



  useLayoutEffect(()=>{
    setHeight()
    window.addEventListener("resize", setHeight)
    return ()=>{
      window.removeEventListener("resize", setHeight)
    }
  }, [])

  return (
    <div className={clsx("partner-page", "partner-page-users")}>
      <Page partner={partner} menu={menu || PartnerPageMenu(partner)} current="Users">      
         <UsersTableB2C partner={partner} height={ tableHeight }  />
      </Page>

    </div>
  )
}