import React from 'react';
import { isFunction, isBoolean } from 'lodash'
import logger from '@kemtai/logger'

//const print = console.log

type Props = Object | null
type RouteSpecification = string | [string, Props, boolean?] | { path: string, props: Props, external?: boolean }
type CheckFunc = (path: string, props?: any) => boolean
type ShowURLFunc = (path: string) => string

export type UrlParams = { [key: string]: string[] }

type AuxRouteSpec = {
    showURL?: boolean | string | ShowURLFunc,
    external?: boolean,
    preConditions?: any
}

function zip<V>(keys: string[], values: V[]) {
    const res: { [key: string]: V } = {}
    for (let index = 0; index < keys.length; index++) {
        res[keys[index]] = values[index]
    }
    return res
}

export function removeLeadingSlashes(path: string) {
    return path.replace(/^\/+/, "")
}

export function removeTrailingSlashes(path: string) {
    return path.replace(/\/+$/, "")
}

export function removeExtraSlashes(path: string) {
    return removeTrailingSlashes(removeLeadingSlashes(path))
}

const idRegexp = "[a-zA-Z_][a-zA-Z0-9_-]+"
const argRegexp = `:(${idRegexp})`
const valRegexp = "([^/]+)"

function pathToRegexp(path: string) {
    const args = [...(path as any).matchAll(new RegExp(argRegexp, "g"))].map(m => m[1])
    if (args.length) {
        let matchRegexp = path.replace(new RegExp(argRegexp, "g"), valRegexp)
        matchRegexp = `^${matchRegexp}$`
        return { args, matchRegexp };
    }
    return { args: [] }
}


export class Route {
    cls: string | null
    path: string
    component: any
    props?: any
    args: string[]
    matchRegexp: RegExp | undefined = undefined

    showURL: boolean | string | ShowURLFunc = true
    external: boolean
    preConditions: CheckFunc | null = null
    redirect: string | null = null

    constructor(cls: string, path: string, component: any, props?: any, aux?: AuxRouteSpec) {
        path = removeExtraSlashes(path)
        this.path = path
        this.component = component
        this.props = props
        this.cls = cls

        this.showURL = aux?.showURL === undefined ? true : aux.showURL
        this.preConditions = aux?.preConditions || null
        this.external = aux?.external === undefined ? true : Boolean(aux?.external)

        const { args, matchRegexp } = pathToRegexp(path)
        this.args = args
        if (matchRegexp) {
            this.matchRegexp = new RegExp(matchRegexp)
        }
    }

    get simple(): boolean {
        return this.args.length === 0
    }

    match(path: string, external: boolean) {
        path = removeExtraSlashes(path)

        if (external && !this.external) {
            return false
        }
        if (this.path === "*") {
            return {}
        }

        if (this.simple) {
            return path === this.path ? {} : null
        } else {
            const values = path.match(this.matchRegexp!)
            //print(">>>>>>>>>>>>>>>>",this.path, values)
            if (values) {
                values.shift()
                //print("**",this.args,values)
                return zip(this.args, values)
            }
        }
        return null
    }

    checkPreConditions(path: string, props?: any): boolean {
        //console.log("checkPreConditions")
        if (!this.preConditions) {
            return true
        }
        return this.preConditions(path, props)
    }

    show(page: string): string {
        if (isFunction(this.showURL)) {
            return this.showURL(page)
        } else if (isBoolean(this.showURL)) {
            return this.showURL ? page : ""
        } else {
            return this.showURL
        }
    }
}


export class Router {
    cls: string | null = null
    routes: Route[] = []
    defaultRoute = ""
    component: any = null
    path: RouteSpecification = "/"
    pathname: string = ""
    props: any = {}
    stack: [string, any][] = []
    readonly origURL: Location;


    urlParams: UrlParams = {}

    constructor() {


        this.origURL = { ...window.location };

        this.urlParams = this.urlParamsCompare()

    }


    route(cls: string, path: string, component: any, props?: any, aux?: AuxRouteSpec) {
        const r = new Route(cls, path, component, props, aux)
        this.routes.push(r)
    }

    /*
    redirect(path:string, to:string, props?:any ){
      const r = new Route(path,null)
      r.redirect = to
      this.routes.push(r)
    }*/

    enroute(path: string, props?: any, external: boolean = false, back: boolean = false): any {
        for (let route of this.routes) {
            const goodCls = route.cls === "*" || this.cls === route.cls
            if (!goodCls) {
                continue
            }
            //console.log(`***> ${route.cls} ${route.path} | ${this.cls} | ${goodCls}`)
            let match = route.match(path, external)

            if (match) {

                if (!route.checkPreConditions(path, props)) {
                    console.log("checkPreConditions fail")
                    return this.pop()
                }

                if (route.redirect) {
                    return this.enroute(route.redirect, route.props)
                }


                const Comp = route.component
                const defaultProps = route.props


                const urlProps = { urlParams: this.urlParams }

                let mergedProps = { ...defaultProps, ...props, ...urlProps, ...match }


                if (!back) {
                    const show = route.show(path)
                    //print("======================>",show,path,route)
                    if (show) {
                        const urlSearch = this.origURL.search
                        window.history.pushState({ path, props }, "", show.replace(/^\/*/, "/") + urlSearch)
                    }
                }
                const Wrapper = (props: any) => { return <Comp {...props} /> }
                return (<Wrapper {...mergedProps} />)
            }
        }

        if (path!=="/") {
            console.log(`No route for ${this.cls} ${path}`)
            return this.enroute("/")
        }

        /*
        if (this.defaultRoute) {
            return this.enroute(this.defaultRoute)
        }
        */
        //return this.notFound()
        return (<h1>No route for {this.cls} {path}</h1>)
        //return null
    }

    navigate(path: string, props: any = {}, external: boolean = false, back: boolean = false) {
        //print("[[[ navigate>>>>>>>>:", path)
        logger.event("navigate", { path: path })
        //const [path, props] = toPair(r)
        this.path = { path, props, external }
        this.pathname = path
        this.props = props
        this.component = this.enroute(path, props, external, back)
        //print("[[[ navigate>>>>>>>>:",this.component)
        return this.component
    }

    fakeNavigate(path: string, props: any = {}, external: boolean = false, back: boolean = false) {
        this.path = { path, props, external }
        this.pathname = path
        this.props = props
        const component = this.enroute(path, props, external, back)
        return component
    }



    urlParamsCompare = () => {
        const search = this.origURL.search.replace('?', '')
        let params: { [key: string]: string[] } = {}
        if (search.length) {
            search.split('&').forEach(param => {
                const [k, v] = param.split('=')
                if (params[k] === undefined) {
                    params[k] = []
                }
                params[k].push(v)
            })
        }
        return params
    }


    /*
    urlParamsValue = (name:string|null=null)=>{
      if(!this.urlParams.length){
        return null
      }
      if(!name){
        return this.urlParams.map(param=>param.value)
      }
      const value = this.urlParams.find(param => param.name === name)
      return value ? value.value : null
    }
  
    urlParamsAdd = (name: string, value=true)=>{
      if(name){
        this.urlParams.push({
          name: name,
          value: value
        })
      }
    }
  
    urlParamsRemove = (name: string)=>{
      const idx = this.urlParams.findIndex(param => param.name === name)
      if(idx){
        this.urlParams.splice(idx, 1)
      }
    }
    */

    urlParamsClear = () => {
        this.origURL.search = ''
        this.urlParams = {}
    }



    pushCurrent() {
        this.push(this.pathname, this.props)
    }
    push(path: string, props: any = {}) {
        this.stack.push([path, props])
    }

    pop() {
        if (this.stack.length) {
            const [path, props] = this.stack[this.stack.length - 1]
            return this.navigate(path, props)
        } else {
            return this.navigate(this.defaultRoute)
        }
    }

    peek() {
        if (this.stack.length) {
            const [path] = this.stack[this.stack.length - 1]
            return path;
        } else {
            return "";
        }
    }

    removeLeadingSlashes(path: string) {
        return removeLeadingSlashes(path);
    }


}

//const theRouter = new Router();
//export default theRouter;
