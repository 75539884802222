import {Box} from "@mui/material";
import React, {ReactChildren, ReactElement, ReactNode} from "react";
import "./style.scss"
import SideMenu from "../SideMenu";
import { TMenu } from "components/SideMenu/types";


type Props = {
  children: ReactChildren | ReactNode | ReactElement
  current?:string
  menu?:TMenu
  menuCollapsed?:boolean
  onChange?:(selected:string)=>void
}

export default ({children, current, menu, onChange,menuCollapsed, ...props}:Props)=>{

  return (
    <>
      <SideMenu menu={menu} current={current || ''} onChange={onChange} collapsed={menuCollapsed}/>
      <Box component="main" className="main-container" {...props}>
        {children}
      </Box>
    </>
  )

}