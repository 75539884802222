import React from 'react';
import Page from 'components/Page';
import { PartnerPageMenu } from 'components/SideMenu/menus';

import {Box} from "@mui/material"
import PeopleIcon from '@mui/icons-material/People';
import { TMenu } from 'components/SideMenu/types';
import CarePtStats from 'components/wg/CarePtStats';

type UserStatsPageProps = {
    partner : string
    menu? : TMenu

}


export function PTStats(props:UserStatsPageProps) {

    return (
        <Page partner={props.partner} menu={props.menu || PartnerPageMenu(props.partner)} current="pt">
            <Box>
          <h3 className="page-title">{ <PeopleIcon/>}PT stats</h3>

        </Box>

        <CarePtStats partner={props.partner} />

        </Page>
    )
}