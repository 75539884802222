import {Grid} from "@mui/material";
import Page from 'components/Page';
import { TMenu } from 'components/SideMenu/types';
import { PartnerPageMenu } from 'components/SideMenu/menus';
import ExerciseCounts from 'components/wg/ExerciseCounts';
import ExerciseUserCounts from 'components/wg/ExerciseUserCounts';
import {useState} from "react";
import DateRangeFilter from 'components/DateRangeFilter';

type Props = {
    partner : string
    menu? : TMenu
  }
  

const PartnetPageGPU = ({partner,  menu}:Props)=>{
    const [startDate, setStartDate] = useState<Date|undefined>(undefined);
    const [endDate, setEndDate] = useState<Date|undefined>(undefined);

    return (
        <>

        <Page partner={partner} menu={menu || PartnerPageMenu(partner)} current="Exercise Statistics">
        <h3 className="page-title">Exercise Statistics</h3>
        
        <DateRangeFilter startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />

        <Grid container spacing={3} mb={3}>
            <Grid item  xs={12} lg={6}><ExerciseCounts partner={partner} start={startDate} end={endDate}/></Grid>
            <Grid item  xs={12} lg={6}><ExerciseUserCounts partner={partner} start={startDate} end={endDate}/></Grid>
        </Grid>
        </Page>
      </>
    )

}

export default PartnetPageGPU;