import React from 'react';
import Page from 'components/Page';
import { MainMenu } from 'components/SideMenu/menus';
import { TMenu } from 'components/SideMenu/types';
import BaseFunnel from 'components/Funnel';
import GoogleKeywordsCounts from 'components/wg/GoogleKeywordsCounts';
import CountryTable from 'components/wg/CountryTable';
import {Grid} from "@mui/material";

export type FunnelPageProps = {
    type?: string
    partner: string
    startDate?: Date
    endDate?: Date
    menu? : TMenu,
}


class Funnel extends BaseFunnel {
    baseUrl = "b2c_funnel"
}

class KWFunnel extends BaseFunnel {
    baseUrl = "b2c_kw_funnel"
}

class CountryFunnel extends BaseFunnel {
    baseUrl = "b2c_country_funnel"
}

class OSFunnel extends BaseFunnel {
    baseUrl = "b2c_os_funnel"
}
class FBADFunnel extends BaseFunnel {
    baseUrl = "b2c_fbad_funnel"
}



export function B2CFunnelPage(props:FunnelPageProps) {
    return (
        <Page partner={props.partner} menu={props.menu || MainMenu} menuCollapsed current="B2C Funnel">
            <Funnel versionFilter partner={props.partner} type={props.type} startDate={props.startDate} title="B2C Funnel"/> 

             <Grid container spacing={3} mb={3}>
                <Grid item xs={12} lg={12}> 
                </Grid>
                <Grid item  xs={12} lg={6}><CountryTable partner={props.partner} /></Grid>
                <Grid item  xs={12} lg={6}><GoogleKeywordsCounts partner={props.partner} /></Grid>
                <Grid item xs={12} lg={12}> 
                <KWFunnel  versionFilter partner={props.partner} type={props.type} startDate={props.startDate} title="Google Keywords Funnel"/> 
                <FBADFunnel versionFilter partner={props.partner} type={props.type} startDate={props.startDate} title="Facebook Ad Funnel"/> 

                <CountryFunnel versionFilter partner={props.partner} type={props.type} startDate={props.startDate} title="Funnel by Country"/> 
                <OSFunnel versionFilter partner={props.partner} type={props.type} startDate={props.startDate} title="Funnel by OS"/> 

                </Grid>

             </Grid>

        </Page>
    )
}   
