import React from "react";
//import Router from "../../router/Router";
import app from 'app'
import {TMenuItem} from "./types";
import {Collapse, Divider, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon  from '@mui/icons-material/ExpandLess';


type Props = {
  item:TMenuItem
  current: string
  collapsed?: boolean
  onChange?: (selected:string)=>void
}
export default ({item, current, collapsed, onChange}:Props)=>{

  if(!item){
    return null
  }

  const initialOpen = item.submenu && item.submenu.map((s) => s && s.title).includes(current) || false
  const [open, setOpen] = React.useState(initialOpen)

  function handleClick() {
    setOpen(!open)
  }

  return <>
      { !collapsed &&
        <ListItemButton onClick={handleClick} >
          <ListItemIcon>{ item.icon }</ListItemIcon>
          {!collapsed &&
            <>
              <ListItemText primary={item.title} />
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </>
          }
        </ListItemButton>
      }
      <Collapse in={ collapsed ? true : open } timeout="auto" unmountOnExit>
        <Divider />
        { item.submenu?.map((menu)=> menu && (
            <ListItemButton key={menu.title}
                selected={ current===menu.title}
                onClick={()=>{
                  if(menu.link) {
                   app.navigate(menu.link)
                  } else if(onChange){
                    onChange(menu.title)
                  }
                }}
                className="side-menu-submenu-button">
              <ListItemIcon>{ menu.icon }</ListItemIcon>
                {!collapsed && <ListItemText primary={menu.title} secondary={ menu.subtitle } /> }
            </ListItemButton>
          ) )}
      </Collapse>
    </>
}