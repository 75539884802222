import React from 'react';
import Page from 'components/Page';
import { PartnerPageMenu } from 'components/SideMenu/menus';

import {Box} from "@mui/material"
import DataTableCard from 'components/DataTableCard';
import EmojiPeopleOutlinedIcon from '@mui/icons-material/EmojiPeopleOutlined';
import BarPlotCard from "components/BarPlotCard";
import TreatmentDuration from 'components/wg/TreatmentDuration';
import FirstDaySetsDistributionByOs from 'components/wg/FirstDaySetsDistributionByOs';

import { TMenu } from 'components/SideMenu/types';
import {useState} from "react";
import DateRangeFilter from 'components/DateRangeFilter';
import {Grid} from "@mui/material";


import "./style.scss"
import FirstDaySetsHistogramByOs from 'components/wg/FirstDaySetsHistogramByOs';
import {date2string} from 'utils';
import WeekWorkoutUsers from 'components/wg/WeekWorkoutUsers';
import WeekWorkoutUsersPlot from 'components/wg/WeekWorkoutUsersPlot';

import WeekWorkoutMean from 'components/wg/WeekWorkoutMean';
import FinishedWorkouts from 'components/wg/FinishedWorkouts';
import PctFinishedWorkouts from 'components/wg/PctFinishedWorkouts';
import ExercisesPerWorkout from 'components/wg/ExercisesPerWorkout';
import WeekExerciseMean from 'components/wg/WeekExerciseMean';
import WorkoutsPerWorkoutDay from 'components/wg/WorkoutsPerWorkoutDay';

type UserStatsPageProps = {
    partner : string
    noMobileSessionsGraph? : boolean
    type? : string
    menu? : TMenu

}


export function Adherence(props:UserStatsPageProps) {
  const [startDate, setStartDate] = useState<Date|undefined>(undefined);
  const [endDate, setEndDate]     = useState<Date|undefined>(undefined);

    return (
        <Page partner={props.partner} menu={props.menu || PartnerPageMenu(props.partner)} current="Adherence">
            <Box>
          <h3 className="page-title">{ <EmojiPeopleOutlinedIcon/>}Adherence</h3>

          <DateRangeFilter startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />


          <BarPlotCard title="Histogram of exercise days per user"
                       partner={props.partner}
                       params={{start:date2string(startDate), end:date2string(endDate)}}
                       url="ex_day_hist"
                       xName="exercise_days"
                       yName="count" />

        <FinishedWorkouts partner={props.partner} />
        <PctFinishedWorkouts partner={props.partner} />


          <DataTableCard url="data/p/ex_day_table" partner={props.partner} title="Users exercising on at least X different days" aux_url_params={{start:date2string(startDate), end:date2string(endDate)}}/>

          <DataTableCard url="data/p/ex_count_table" partner={props.partner} title="Users performed at least X different exercies" 
                                                aux_url_params={{start:date2string(startDate), end:date2string(endDate)}}/>

          {!props.noMobileSessionsGraph &&
            <DataTableCard url="data/p/ex_day_table" partner={props.partner} aux_url_params={{mobile:"1",start:date2string(startDate), end:date2string(endDate)}} title="MOBILE ONLY users exercising on at least X different days" />
          }
        

        <Grid container spacing={3} mb={3}>
        <Grid item xs={12} lg={6}>
          <DataTableCard url="data/p/month_infuence" partner={props.partner} title="Time Influence" 
                aux_url_params={{start:date2string(startDate), end:date2string(endDate)}} 
          /></Grid>
        <Grid item xs={12} lg={6}>
          <TreatmentDuration partner={props.partner} aux_url_params={{start:date2string(startDate), end:date2string(endDate)}} />
          </Grid></Grid>
          <p></p>
          <FirstDaySetsDistributionByOs partner={props.partner} start={date2string(startDate)} end={date2string(endDate)} key={`a-${props.partner}-${date2string(startDate)}-${date2string(endDate)}`} />
          <p></p>
          <FirstDaySetsHistogramByOs    partner={props.partner} start={date2string(startDate)} end={date2string(endDate)} key={`b-${props.partner}-${date2string(startDate)}-${date2string(endDate)}`} />
          <Grid container spacing={3} mb={3}>

          <Grid item xs={12} lg={4}><WeekWorkoutUsers partner={props.partner} start={startDate} end={endDate} periodSubtitle={" counting only users joined "} /></Grid>

          <Grid item xs={12} lg={4}><WeekWorkoutMean  partner={props.partner} start={startDate} end={endDate} periodSubtitle={" counting only users joined "} /></Grid>
          <Grid item xs={12} lg={4}><WeekExerciseMean  partner={props.partner} start={startDate} end={endDate} periodSubtitle={" counting only users joined "} /></Grid>
          
          {props.partner !== "ro" && <> 
            <Grid item xs={12} lg={4}><ExercisesPerWorkout  partner={props.partner}  /></Grid>
            <Grid item xs={12} lg={4}><WorkoutsPerWorkoutDay  partner={props.partner}  /></Grid>
          </>}

           </Grid>
           <WeekWorkoutUsersPlot partner={props.partner} start={startDate} end={endDate} periodSubtitle={" counting only users joined "} />

        </Box>

        </Page>
    )
}