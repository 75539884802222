import React from 'react';
import DataTableCard from 'components/DataTableCard';
import strftime from 'strftime';


type ExerciseUserCountsProps = {
    partner : string | string[]
    start? : Date
    end? : Date
}


function ExerciseUserCounts(props:ExerciseUserCountsProps) {
    console.log("ExerciseUserCounts", props);


    const startDate = props.start ? strftime("%Y-%m-%d", props.start) : ""
    const endDate = props.end ? strftime("%Y-%m-%d", props.end) : ""


    return (
        <DataTableCard url={`exercise-user-counts/`}
        partner={props.partner}
        aux_url_params={{start:startDate, end:endDate}}
        title="Exercise User Counts" subtitle="how many distinct users performed each exercise" />
    )
}

export default ExerciseUserCounts;
