import React from 'react';
import Api from 'app/api';
import Loader from "components/Loader"

//const print = console.log

function sleep(ms:number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

type LoadingAnimationProps = {
    text ? : string
}

export class LoadingAnimation extends React.Component<LoadingAnimationProps,{n:number}> {
    state = {n:0}
    timer : any = null
    dots = 25
    tick = () => {
        this.setState({n : this.state.n + 1})
        this.timer = setTimeout(this.tick, 200)
        //console.log("tick")
    }

    componentDidMount(){
        this.tick()
    }
    componentWillUnmount(){
        if (this.timer) {
            clearTimeout(this.timer)
            this.timer = null
        }
    }

    text(){
        return this.props.text || "Loading data"
    }

    render() {
        return (<h4 className="loading-animation">{". ".repeat(this.state.n % this.dots)} {this.text()} {". ".repeat(this.dots - this.state.n % this.dots)} </h4>)
    }
}

export interface DataComponentState {
    //loading: boolean,
    data : any
}

class DataComponent<P={},S extends DataComponentState = DataComponentState> extends React.Component<P,S> {
    data: any;
    //loading: boolean = false;
    _url: string = "";


    get url(): string {
        return this._url;
    }
    get params(): any {
        return {}
    }

    setCursor(cursor: string) {
        document.body.style.cursor = cursor;
    }

    async loadData() {

        this.setCursor("wait")
        const data = await Api.Get(this.url, this.params)
        this.data = data.data
        this.setCursor("default")
        this.setState({data:this.data})
        await sleep(1)
        this.onLoad()
    }

    async reload() {
        const data = await Api.Get(this.url, this.params)
        this.data = data.data
        this.setState({data:this.data})
        await sleep(1)
        this.onLoad()
    }



    onLoad() { }

    componentDidMount() {
        this.loadData()
    }

    title(): JSX.Element | null {
        return null;
    }

    render(): JSX.Element | null {
        if (!this.data) {
            return (<>
                {this.title()}
                {this.loading_placeholder()}
            </>)
        }
        return this._render()
    }

    _render(): JSX.Element | null {
        return null
    }


    loading_text() : string|undefined {
        return undefined
    }
    
    loading_placeholder() {
        return <Loader />
        //return <LoadingAnimation text={this.loading_text()} />;
        //return (<h4>Loading data... </h4>)
    }

}

export default DataComponent;
