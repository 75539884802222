import Link from 'router/Link';
import { durationSort, userSort } from 'utils/colutils';
import DataTableCard from "../../components/DataTableCard";


type Props = {
  partner : string
  height?: number
  admin?: boolean
}

function user(row: any, partner:string) {
    const user = row.cid
    return <Link to={`/sessions/${encodeURIComponent(user)}`}>{user}</Link>
}

function duration(row: any) {
    const d = Math.round(row.duration)
    if (d<60) {
        return `${d}s`
    } else {
        const dm = Math.round(d/60)
        if (dm<60) {
            return `${dm}m`
        } else {
            const dh = Math.round(dm/60)
            return `${dh}h`

        }

    }
}


const UsersTable =({partner}:Props)=> {
  const COLUMNS = [
    {name: 'experiment_group',      selector: 'experiment_group',     sortable: true, wrap:true},
    {name: 'facebook_ad_id',      selector: 'facebook_ad_id',     sortable: true, wrap:true},
    {name: 'keyword',      selector: 'keyword',     sortable: true, wrap:true},

    {name: 'user',        selector: (row:any)=>user(row, partner), sortFunction:userSort,     sortable: true, wrap:true},
    {name: 'First Visit', selector: 'started', sortable: true, wrap:true},
    //{name: 'Last Visit',  selector: 'ended',   sortable: true, wrap:true},
    {name: 'Duration',    selector: duration,  sortFunction:durationSort, sortable: true,                wrap:true},
    //{name: '#exercises',  selector: "exercise_started", sortable: true, wrap:true},
    {name: '#sessions',   selector: 'nsessions', sortable: true, wrap:true},
    {name: 'Country',      selector: 'country',       sortable: true, wrap:true},
    {name: 'OS',      selector: 'os',       sortable: true, wrap:true},
    //{name: 'Device',      selector: 'mob',       sortable: true, wrap:true},
    //{name: 'Model',      selector: 'models',     sortable: true, wrap:true},

  ]
    
    return (
      <DataTableCard url={ `users-b2c?partner=${partner}` } columns={COLUMNS} height='full' />
      )

}


export default UsersTable;
