import React from 'react';
import DataTableCard from 'components/DataTableCard';


type GoogleKeywordsCountsProps = {
    partner : string
}

function GoogleKeywordsCounts(props:GoogleKeywordsCountsProps) {
    return (
        <DataTableCard url="google-keywords-counts/" partner={props.partner} title="Google Keywords" subtitle="" />
    )
}

export default GoogleKeywordsCounts;
