import React from 'react';
import DataComponent from 'components/DataComponent';
import Chart from 'react-apexcharts'
import { Card, CardContent } from "@mui/material"
import ExcelIcon from 'components/ExcelIcon';


type MonitoringSatisticsProps = {
   // partner : string
}

/* v 2 */

class MonitoringSatistics extends DataComponent<MonitoringSatisticsProps> {
  baseUrl = "monitoring-satistics/"

  get url() {
    return `${this.baseUrl}`
  }

  _render() {
    return (
      <Card className="page-content-card">
        <CardContent>
          <h4 className="page-content-card-title"> Monitoring Satistics <span>  </span>
           <ExcelIcon url={this.url+"?xl=1"} filename="Monitoring Satistics"/> 
          </h4>
          <Chart
            options={ {
              xaxis: { categories: this.data.date },
              //plotOptions: { hideOverflowingLabels: false },
            } }
            series={ [
                      { name: "production #passed", data: this.data.prod_passed , color: "#00FF00"},
                      { name: "production #failed", data: this.data.prod_failed  , color: "#FF0000"},
                    
                      { name: "sandbox passed", data: this.data.sandbox_passed  , color: "#006600"},                    
                      { name: "sandbox failed", data: this.data.sandbox_failed  , color: "#660000" },
                                        
                      { name: "uk-prod passed", data: this.data.uk_passed  , color: "#008800"},                
                      { name: "uk-prod failed", data: this.data.uk_failed   , color: "#880000"},

                      { name: "ga-prod passed", data: this.data.ga_passed  , color: "#00AA00"},    
                      { name: "ga-prod failed", data: this.data.ga_failed   , color: "#AA0000"},
            ] }
            type="line"
            width="100%" height={450}
          />
        </CardContent>
      </Card>
    );
  }
}


export default MonitoringSatistics;
