import React from 'react';
//import Router from './Router';
import app from 'app';


type LinkProps = {
  to: string,
  nav?:string,
  className?:string,
  style?:any
  props?:any
}

class Link extends React.Component<LinkProps> {

  render() {

    const style = {color:"blue",...this.props.style}

    return (
      <a href={this.props.to} style={style} 
         onClick={(e)=>{app.navigate(this.props.nav||this.props.to,this.props.props); e.preventDefault(); return false}}>{this.props.children} </a>
    );

  }

}

export default Link;
