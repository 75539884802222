import {Grid} from "@mui/material";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import DataTableCard from "components/DataTableCard";
import BarPlotCard from "components/BarPlotCard";
import Page from 'components/Page';
import { TMenu } from 'components/SideMenu/types';
import { PartnerPageMenu } from 'components/SideMenu/menus';
import PartnerFunnelByModel from "components/wg/PartnerFunnelByModel";
import PartnerFunnelByModeliOS from "components/wg/PartnerFunnelByModelIos";
import PartnerFunnelByModelAndroid from "components/wg/PartnerFunnelByModelAndroid";

type Props = {
  partner : string
  menu? : TMenu
}

const PartnetPageGPU = ({partner,  menu}:Props)=>{

    return (
        <>
        <Page partner={partner} menu={menu || PartnerPageMenu(partner)} current="Model and GPU">

          <h3 className="page-title">{ <AssessmentOutlinedIcon /> } Model and GPU</h3>


          <Grid container spacing={3} mb={3}>

          <Grid item xs={12} lg={12}>  <PartnerFunnelByModel partner={partner} />         </Grid>
          <Grid item xs={12} lg={12}>  <PartnerFunnelByModeliOS partner={partner} />      </Grid>
          <Grid item xs={12} lg={12}>  <PartnerFunnelByModelAndroid partner={partner} />  </Grid>



            <Grid item xs={12} lg={12}>

              <BarPlotCard partner={partner}
                        url="gpu_chart"
                        title="Sessions by GPU"
                        xName="gpu"
                        yName="count" />
            </Grid>
            <Grid item  xs={12} lg={12}>

              <BarPlotCard partner={partner}
                          url="gpu_user_chart"
                          title="Unique Users by GPU"
                          xName="gpu"
                          yName="count" />
            </Grid>
            <Grid item  xs={12} lg={6}>

            <BarPlotCard partner={partner}
                          url="model_chart"
                          title="# Sessions by model"
                          xName="model"
                          yName="count" />
            </Grid>

            <Grid item xs={12} lg={6}>

              <BarPlotCard partner={partner}
                           url="model_chart_os"
                           params={{os:"iOS"}}
                           title="# Sessions by model @ iOS "
                           xName="model"
                           yName="count" />
            </Grid>
            <Grid item xs={12} lg={6}>

              <BarPlotCard partner={partner}
                          url="model_chart_os"
                          params={{os:"Android"}}
                          title="# Sessions by model @ Android "
                          xName="model"
                          yName="count" />
            </Grid>

          <Grid item xs={12} lg={6}>
          <BarPlotCard partner={partner}
                          url="model_user_chart"
                          title="Unique Users by model"
                          xName="model"
                          yName="count" />            
          </Grid>


            <Grid item xs={12} lg={6}>

              <BarPlotCard partner={partner}
                          url="model_user_chart_os"
                          title="Unique Users by model @ iOS"
                          params={{os:"iOS"}}
                          xName="model"
                          yName="count" />
            </Grid>
            <Grid item xs={12} lg={6}>

              <BarPlotCard partner={partner}
                          url="model_user_chart_os"
                          title="Unique Users by model  Android "
                          params={{os:"Android"}}
                          xName="model"
                          yName="count" />
            </Grid>
            <Grid item xs={12} lg={6}>

            <DataTableCard url="data/p/model_infuence" partner={partner} title="Model Influence" />
            </Grid>


          </Grid>


        </Page>
      </>
    )

}

export default PartnetPageGPU;