import React from 'react';
import DataComponent from "components/DataComponent";
import ActiveStory from './ActiveStory';
import { MainMenu } from 'components/SideMenu/menus';
import Page from "components/Page";



class ActiveSessions extends DataComponent<{}> {
    _url: string = "active_sessions/"

    interval: NodeJS.Timeout | null = null

    async onLoad() {
        if (this.interval){
            clearInterval(this.interval)
        }
        this.interval = setTimeout(()=>this.reload(), 10_000);
    }

    componentWillUnmount() {
        if (this.interval){
            clearInterval(this.interval)
            this.interval = null
        }
    }


    render(){
        console.log("ActiveSessions:",this.data)

        if (!this.data?.sessions) {
            return null
        }
        
        return (<div style={{display: "flex",  flexWrap: "wrap" }}>
            { this.data.sessions.map((sess:any, idx:number)=>{
                return <ActiveStory session={sess.session} key={sess.session}/>
            })}
            </div>)
               
    }
}

function ActiveSessionsPage(){
    return (
        <Page menu={MainMenu} current="Now">
            <h3 className="page-title">Running now:</h3>
            <ActiveSessions/>
        </Page>
    )
}



export default ActiveSessionsPage;


