import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import "./style.scss"

export default function Loader() {

  return (
    <Backdrop className="backdrop-loader" open >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}