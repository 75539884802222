import React from 'react';
import DataTableCard from 'components/DataTableCard';
import strftime from 'strftime';



type WeekWorkoutUsersProps = {
    partner : string | string[]
    start? : Date
    end? : Date
    periodSubtitle? : string
}

function WeekWorkoutUsers(props:WeekWorkoutUsersProps) {

    const startDate = props.start ? strftime("%Y-%m-%d", props.start) : ""
    const endDate = props.end ? strftime("%Y-%m-%d", props.end) : ""


    return (
        <DataTableCard url="week-workout-users/" 
            partner={props.partner} 
            aux_url_params={ {start:startDate, end:endDate, periodSubtitle:props.periodSubtitle} }
            title="Week-Workout-Users" 
            subtitle="how many users did X workouts in their Y week" 
        />
    )
}

export default WeekWorkoutUsers;
