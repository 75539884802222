import axios from 'axios';
import Config from '../config';

enum Action {
  Post = "POST",
  Get = "GET",
};


class Api {
    token : string = ""
    
    skeleton(rid:number|string){
      const url = `${Config.serverURL}/api/data/raw/skeleton/${rid}`
      return axios.get(url, this.headers())
    }


    async download(url:string, filename:string = "filename") {
      url = `${Config.serverURL}/api/${url}`;
      console.log("DOWNLOAD >>>",url)
      const resp = await axios.get(url, {...this.headers(), responseType: 'blob'})
      const blob = new Blob([resp.data], {type:resp.headers['content-type']});
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = filename + ".xlsx";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();    
      a.remove();  //afterwards we remove the element again         
    }

    Get(url:string, params:any={}) {
      url = `${Config.serverURL}/api/${url}`
      return axios.get(url, {...this.headers(), params})
    }

    Fetch(url:string) {
      url = `${Config.serverURL}/api/${url}`
      return fetch(url, {...this.headers()})
    }

    headers() {
        if (this.token) {
            return { headers: { Authorization: 'Token ' + this.token }}
        } else {
            return {}
        }
    }
    
    request(url: string, action: Action) {
      url = `${Config.serverURL}/api/${url}`;
    
      switch (action) {
        case Action.Post:
          return (data?: any)   => axios.post(url, data, this.headers());
        case Action.Get:
          return (params?: any) => axios.get(url, { params:params, ...this.headers()});
        default:
          throw new Error("Invalid action");
      }
    }
    
    
    post(url: string) {
      return this.request(url, Action.Post);
    }
    
    get(url: string) {
      return this.request(url, Action.Get);
    }

    checkToken(data?:any) {
        return this.post("check-token/")(data)
    }
    login(data?:any) {
        return this.post("login/")(data)
    }

}

export default new Api();
