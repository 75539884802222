import React, {useState} from "react";
import {AppBar, Avatar, Box,  IconButton, Menu, MenuItem, Toolbar, Typography} from "@mui/material";
import {logo} from "utils/logo";
import app from 'app'

import "./style.scss"


type Props = {
  partner?: string
  page?:string
}

export default ({partner}:Props)=>{
  const user = app.user
  const [anchorElUser, setAnchorElUser] = useState<null|HTMLElement>(null)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  }
  const handleMenuClose = () => {
    setAnchorElUser(null);
  }

  return (
    <AppBar className="page-header">

      <Toolbar >
        {partner ? <Avatar src={ logo(partner) } alt={partner} className="partner-page-logo" />: <div></div>}

        <Box display="flex" alignItems="center">

          <Box>
            <IconButton id="userAvatar" onClick={handleMenuOpen} >
              <Avatar alt={user?.name} src={user?.picture} />
            </IconButton>
            <Menu id="menu-appbar"
              keepMounted
              anchorEl={anchorElUser}
              open={ Boolean(anchorElUser) }
              onClose={handleMenuClose}
              anchorOrigin={{vertical: 'top',horizontal: 'right'}}
              transformOrigin={{vertical: 'top',horizontal: 'right'}}
              >
              <MenuItem onClick={()=>app.navigate(`/logout`)}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>

    </AppBar>
  )
}