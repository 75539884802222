import DataTable from "react-data-table-component";
import React, {useCallback, useLayoutEffect, useRef, useState} from "react";
import {Box, Card, CardContent} from "@mui/material";
import useDataLoader from "hooks/useDataLoader";
import { paramsToUrl, UrlParamsT } from "utils"
import Loader from "components/Loader"
import ErrorAlert from "components/ErrorAlert"
import {col} from "utils/colutils";
import Link from "router/Link";
import { list, partner2Url } from 'utils/mp';
import api from "app/api";

import ExcelIcon from "components/ExcelIcon";

function makeColumnDef(cdef:any) {
    if (typeof(cdef) === 'string' ) {
        return col(cdef) 
    } 
    
    if (cdef.link) {
        cdef.selector = (row:any)=><Link to={row.link.to} nav={row.link.nav} props={row.link.props}>{cdef.link}</Link>;
    }

    return cdef
}

function mkUrl(baseUrl : string, moreUrl:string) {
    if (!moreUrl) {
        return baseUrl
    }
    if (baseUrl.includes("?")) {
        return baseUrl + "&" + moreUrl
    } else {
        return baseUrl + "?" + moreUrl
    }
}

type Props = {
  url: string,
  aux_url_params?: UrlParamsT
  partner? : string|string[]
  title?: string,
  subtitle?: string,
  height?: number|'full'
  heightShift?: number
  columns?: any
  footer ?: string
}
export default (props:Props)=>{
  const {url, aux_url_params, partner, title, subtitle, height, heightShift, columns} = props
  const wrapperRef = useRef<HTMLElement>()
  const [tableHeight, setTableHeight] = useState(500)
  const dataUrl = mkUrl(url,`${partner2Url(partner)}${paramsToUrl(aux_url_params)}`)
  const {loading, data, error} = useDataLoader(dataUrl)
  //console.log(">>>",url,"||",partner2Url(partner), paramsToUrl(aux_url_params))
  const setHeight = useCallback(()=>{
    if(wrapperRef.current && wrapperRef.current.offsetHeight > 0){
      setTableHeight(wrapperRef.current.offsetHeight-45)
    }
  }, [])

  useLayoutEffect(()=>{
    setHeight()
    window.addEventListener("resize", setHeight)
    return ()=>{
      window.removeEventListener("resize", setHeight)
    }
  }, [data])

    let actualSubtitle = subtitle ?? ""
    if (aux_url_params?.start || aux_url_params?.end) {
        actualSubtitle += " | " + aux_url_params.periodSubtitle??""
    }

    if (aux_url_params?.start) {
          actualSubtitle += ` from ${aux_url_params.start} `
    }
    if (aux_url_params?.end) {
        actualSubtitle += ` till ${aux_url_params?.end} `
    }
    actualSubtitle += " | " + list(partner).join(",")


  return (
    <Card className="page-content-card">
      <CardContent>
        { title && <h4 className="page-content-card-title">{ title }{ actualSubtitle ? <span>{ actualSubtitle }</span> : null}
         <ExcelIcon url={dataUrl+"&xl=1"} filename={title}/> </h4> }

        <Box ref={wrapperRef} sx={{height: height==='full' ? `calc(100vh - ${heightShift ? heightShift : title ? '232' : '185'}px)` : height ? `${height}px` : '100%',overflow:'hidden',position:'relative',flexGrow:1}}>
        { loading
          ? <Loader />
          : error
            ? <ErrorAlert error={ error } />
            :
              <>
              <DataTable
                data={ data.data }
                striped highlightOnHover fixedHeader noHeader dense
                fixedHeaderScrollHeight={height ? `${tableHeight}px` : undefined}
                columns={ columns ? columns : data.columns.map(makeColumnDef)} />
              {data.footer && <>{data.footer}</>}</>
        }
        </Box>
      </CardContent>
    </Card>
  )

}