import DataTableCard from 'components/DataTableCard';
import { MainMenu } from 'components/SideMenu/menus';
import Page from "components/Page";
import {IlRecordingStats,IlRecordingStatsDaily} from 'components/wg/IlRecordingStats';


export default ()=>{


  return (
      <>
      <Page menu={MainMenu} current="Recordings">
        <IlRecordingStats/>
        <IlRecordingStatsDaily/>

        <h3 className="page-title">Recordings by country</h3>
        <DataTableCard url="recordings/venezuela/" partner="" />
      </Page>
    </>
  )

}