import React from 'react';
import DataTableCard from 'components/DataTableCard';
import strftime from 'strftime';



type WeekWorkoutMeanProps = {
    partner : string | string[]
    start? : Date
    end? : Date
    periodSubtitle? : string
}

function WeekWorkoutMean(props:WeekWorkoutMeanProps) {

    const startDate = props.start ? strftime("%Y-%m-%d", props.start) : ""
    const endDate = props.end ? strftime("%Y-%m-%d", props.end) : ""


    return (
        <DataTableCard url="week-workout-mean/" 
            partner={props.partner} 
            aux_url_params={ {start:startDate, end:endDate, periodSubtitle:props.periodSubtitle} }
            title="Workouts per User per Week" 
            subtitle="average number of workous per user at user's X week" 
        />
    )
}

export default WeekWorkoutMean;
