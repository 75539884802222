

const  auth0 = {
    "domain": "dev-uh2oc3pmnvyuv32k.us.auth0.com",
    "clientId": "BzTuir7YDAVot1tNEvgieOYgMwIXLWpk",
    "audience": "AnalyticsBackendAPI"
  }


const devConfig = {
  serverURL: "http://localhost:5001",
  //serverURL: "https://analytics.kemtai.com/",
  auth0
}

const prodConfig = {
  serverURL: "",
  auth0
}

let config = (process.env.NODE_ENV === "development") ? devConfig : prodConfig
export default config;

