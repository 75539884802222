import React from 'react';
import DataTableCard from 'components/DataTableCard';
import strftime from 'strftime';



type CarePtStatsProps = {
    partner : string | string[]
    start? : Date
    end? : Date
}

function CarePtStats(props:CarePtStatsProps) {

    const startDate = props.start ? strftime("%Y-%m-%d", props.start) : ""
    const endDate = props.end ? strftime("%Y-%m-%d", props.end) : ""


    return (
        <DataTableCard url="care-pt-stats/" 
            partner={props.partner} 
            aux_url_params={ {start:startDate, end:endDate} }
            title="PT stats" 
            subtitle="" 
        />
    )
}

export default CarePtStats;
