
export const partnersList = ["maccabi@care","vori@care",'ro','EXI','idf@care','alyn@care','dochq','healthfocus@care','villa-donatello@care']

export const defaultPartnerList = ["maccabi@care","vori@care"]

export function  partner2Url(partner : string | string[] | undefined) {
    if (!partner) {
        return ""
    }
    if (Array.isArray(partner)) {
        return partner.map(partner=>`partner=${partner}`).join("&") 
    } else {
        return `partner=${partner}`
    }

}
export function  list(partner : string | string[] | undefined) {
    if (!partner) {
        return []
    }
    if (Array.isArray(partner)) {
        return partner
    } else {
        return [partner]
    }
}
