import React from 'react';
import Link from 'router/Link';

export default function UnvalidatedEmail() {

    return (

            <div style={{ 
                    display: "flex", 
                    alignItems: "center",
                    justifyContent: "center", 
                    height:"100vh",
                    flexDirection: "column"
                  }}>
                

                  <h1>To access Kemtai Analytics please verify your email!</h1>
                  <Link to="/logout" >Logout        </Link>
            </div>
    );

}


