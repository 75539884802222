import React from 'react';
import DataTableCard from 'components/DataTableCard';
import strftime from 'strftime';



type ExerciseDayCountsProps = {
    partner : string | string[]
    start? : Date
    end? : Date
}

function ExerciseDayCounts(props:ExerciseDayCountsProps) {

    const startDate = props.start ? strftime("%Y-%m-%d", props.start) : ""
    const endDate = props.end ? strftime("%Y-%m-%d", props.end) : ""


    return (
        <DataTableCard url="exercise-day-counts/" 
            partner={props.partner} 
            aux_url_params={ {start:startDate, end:endDate} }
            title="Exercise-Day Counts" 
            subtitle="counts number of exrcise-days for each exercise" 
        />
    )
}

export default ExerciseDayCounts;
