import React, { useState } from 'react';
import Page from 'components/Page';
import {  XPartnerPageMenu } from 'components/SideMenu/menus';

import {Box} from "@mui/material"
import DataTableCard from 'components/DataTableCard';
import VisitsGraph from 'components/VisitsGraph';
import NewUsersGraph from 'components/NewUsersGraph';
import { TMenu } from 'components/SideMenu/types';
import "./style.scss"
import MonthlyStats from 'components/wg/MonthlyStats';
import MultipleSelect from 'components/MultipleSelect';
import {partnersList,defaultPartnerList} from 'utils/mp';

type UserStatsPageProps = {
    noMobileSessionsGraph? : boolean
    noCountryTable? : boolean
    type? : string,
    menu? : TMenu
}

export function UserStatsPageX(props:UserStatsPageProps) {
    const [partners, setPartners] = useState<string[]>(defaultPartnerList);

    return (
        <Page menu={XPartnerPageMenu()} current="User Stats">
            <Box>
            <MultipleSelect allOptions={partnersList} defaultOptions={defaultPartnerList} onChange={setPartners}/>

            <DataTableCard url="data/p/new_user_by_month" partner={partners} title="New Users By Month" 
            subtitle='couning users who finished at least one exercise'/>

            <VisitsGraph partner={partners} kemtapi={props.type==="kemtapi"}/>
    
            <NewUsersGraph partner={partners}/>

            <MonthlyStats partner={partners}/>
        
            </Box>
        </Page>
    )
}

