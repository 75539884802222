import BaseFunnel from 'components/Funnel';
 

class PartnerFunnelByModel extends BaseFunnel {
    baseUrl = "partner-funnel-by-model/"
    funnel_type: string = "partner"
    _title = "Funnel by model"
}


export default PartnerFunnelByModel;
