import React, {useCallback} from 'react';
//import Router from "../router/Router";
import app from 'app'
import DataTableCard from "components/DataTableCard";
import {Link} from "@mui/material";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import { MainMenu } from 'components/SideMenu/menus';
import Page from "components/Page";


const col=(c:string, width?:number, selector?:boolean)=>{
  const sel = (row:any)=>{
      return row.first ? row[c] : ""
  }
  return {name: c, selector: selector ? sel : c, sortable: true, wrap:true, width : width ? `${width}px` : undefined}
}

const COLUMNS = [
  col("rid",100, true),
  col("session",170, true),
  col("user_id",170, true),
  col("user_email",170, true),

  col("time",170, true),
  col("session_time",100, true),
  col("level",150, true),
  col("event",170, true),
  col("key",150),
  {name: 'value', selector: 'value', sortable: true, wrap:true}
]



type Props = {
  session : string
  partner?:string
  admin?:boolean
  page?:string
  title?:string
}

const Session =({session, partner, admin, page, title}:Props)=>{

    const navigateBack = useCallback((e)=>{
      e.preventDefault()
      app.navigate(`/users/${partner}`)
    }, [])


    return page ? (
        <div>
          <Page menu={MainMenu} menuCollapsed>

            <h3 className="page-title">
              {partner &&
                  <>
                    <Link href="#" onClick={navigateBack}><AssignmentIndOutlinedIcon /> Users</Link>
                    {/*<span className="crumb"><Link href="#" onClick={navigateBack}> User Sessions</Link></span>*/}
                    <span className="crumb">Session</span>
                  </>
               
              }
            </h3>

            <DataTableCard url={ `session_details?session=${session}` } columns={COLUMNS} title={title ? title : "User Session"} height='full' />

          </Page>
        </div>
    ) : (
      <DataTableCard url={ `session_details?session=${session}` } columns={COLUMNS} title={title ? title : "User Session"} height='full' />
    )

}


export default Session;
