import BaseFunnel from 'components/Funnel';
 

class PartnerFunnelByModelIos extends BaseFunnel {
    baseUrl = "partner-funnel-by-model-ios/"
    funnel_type: string = "partner"
    _title = "iOS : Funnel by model"
}


export default PartnerFunnelByModelIos;
