import React from 'react';
import {Box, TextField} from '@mui/material'
import app from 'app'
import UserSessions from "./UserSessions"
import Session from "./Session"
import Link from 'router/Link';
import useDataLoader from "hooks/useDataLoader";
import Loader from "components/Loader";
import ErrorAlert from "components/ErrorAlert";
import { MainMenu } from 'components/SideMenu/menus';
import Page from "components/Page";

export interface SearchQuery {
  term? : string
}

export
class SearchForm extends React.Component<SearchQuery> {
  inputRef: HTMLInputElement | null = null;

  onKeyDown = (event:React.KeyboardEvent) => {
    if (event.key==="Enter"){
      console.log(this.inputRef?.value)
      app.navigate("search",{term:this.inputRef?.value})
    }
  }

  render() {
    return (
      <Box justifyContent='flex-end' sx={{display:'flex'}}>
        <TextField inputRef={ref=>this.inputRef=ref} value={this.props.term} onKeyDown={this.onKeyDown} autoComplete='off' label="Search Session" variant="outlined" size="small" />
      </Box>
    )
  }
}



const SearchResults =({term}:SearchQuery)=> {

  const {loading, data, error} = useDataLoader(`search?term=${term}`)

  return loading ? (

        <Loader />

      ) : error ? (

          <ErrorAlert error={ error } />

        ) : data && data.type==='puser' ? (

             <UserSessions title={`User ${data.id}`} puser={data.id} />

          ) : data && data.type==='session' ? (
            <>
              <Link to={`story/${data.id}`}>Session: {data.id} </Link>
              <Session session={data.id} admin />
            </>
          ) : data && data.type==='not_found' ? (
            <>not found</>
          ) : (
            <>{JSON.stringify(data)}</>
          )

}

  export
  function SearchPage(props:SearchQuery) {
    console.log(props)

    const term : string|undefined = props.term

    return <div>
      <Page menu={MainMenu}>
        <Box justifyContent='space-between' alignItems="baseline" mb={2} sx={{display:'flex'}}>
          <h3 className="page-title">Search results</h3>
          <SearchForm term={term} />
        </Box>
        {term && <SearchResults term={term} />}
      </Page>
    </div>
  }



  export default SearchForm;