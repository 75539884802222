import React from 'react';
import DataTableCard from 'components/DataTableCard';


type MonthlyStatsProps = {
    partner : string|string[]
}

function MonthlyStats(props:MonthlyStatsProps) {
    return (
        <DataTableCard url="monthly-stats/" partner={props.partner} title="Monthly Stats" subtitle=" " />
    )
}

export default MonthlyStats;
