import React,{useState} from 'react';
import { Grid} from "@mui/material";
import Page from 'components/Page';
import { TMenu } from 'components/SideMenu/types';
import { XPartnerPageMenu } from 'components/SideMenu/menus';
import ExerciseCounts from 'components/wg/ExerciseCounts';
import ExerciseUserCounts from 'components/wg/ExerciseUserCounts';
import DateRangeFilter from 'components/DateRangeFilter';
import MultipleSelect from 'components/MultipleSelect';
import {partnersList,defaultPartnerList} from 'utils/mp';
import ExerciseSessionCounts from 'components/wg/ExerciseSessionCounts';
import ExerciseDayCounts from 'components/wg/ExerciseDayCounts';

type Props = {
    menu? : TMenu
}


const PartnetPageGPU = ({ menu}:Props)=>{
    const [startDate, setStartDate] = useState<Date|undefined>(undefined);
    const [endDate, setEndDate] = useState<Date|undefined>(undefined);
    const [partners, setPartners] = useState<string[]>(defaultPartnerList);

    return (
        <>

        <Page  menu={menu || XPartnerPageMenu()} current="Exercise Statistics">

        {/* MUI Select multiple partners */}        
        <MultipleSelect allOptions={partnersList} defaultOptions={defaultPartnerList} onChange={setPartners}/>

        {/* Date Range Filter */}

        <DateRangeFilter startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />

        <Grid container spacing={3} mb={3}>
            <Grid item  xs={12} lg={6}><ExerciseUserCounts partner={partners} start={startDate} end={endDate}/></Grid>
            <Grid item  xs={12} lg={6}><ExerciseDayCounts partner={partners} start={startDate} end={endDate}/></Grid>
            <Grid item  xs={12} lg={6}><ExerciseSessionCounts partner={partners} start={startDate} end={endDate}/></Grid>
            <Grid item  xs={12} lg={6}><ExerciseCounts partner={partners} start={startDate} end={endDate}/></Grid>


        </Grid>
        </Page>
      </>
    )

}

export default PartnetPageGPU;