import React from 'react';
import app from 'app'


class Login extends React.Component<{}> {

  login = () => {
    app.login()
  }

  render() {
    
    if (app.isAuthenticated) {
      return null 
    }
    
    return (

            <div style={{ 
                    display: "flex", 
                    alignItems: "center",
                    justifyContent: "center", 
                    height:"100vh",
                    flexDirection: "column"
                  }}>
                
              <h1>Welcome to Kemtai Analytics</h1>
              <button style={{fontSize:20, margin:"25px", padding:"25px", width:"200px" }} onClick={ this.login }>Login</button>

            </div>
    );

  }

}


export default Login;
