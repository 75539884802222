import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css';

//import "./assets/plugins/nucleo/css/nucleo.css";
//import "./assets/css/argon-dashboard-react.css";
//import "@fortawesome/fontawesome-free/css/all.min.css";
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from 'theme';
import {App} from 'app'


ReactDOM.render(
      <ThemeProvider>
        <App/>
      </ThemeProvider>
    ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
