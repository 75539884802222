import React from 'react';
import DataTableCard from 'components/DataTableCard';
import strftime from 'strftime';


type ExerciseCountsProps = {
    partner : string | string[]
    start? : Date
    end? : Date
}


function ExerciseCounts(props:ExerciseCountsProps) {
    const startDate = props.start ? strftime("%Y-%m-%d", props.start) : ""
    const endDate = props.end ? strftime("%Y-%m-%d", props.end) : ""

    return (
        <DataTableCard url={`exercise-counts/`} 
        partner={props.partner} 
        aux_url_params={{start:startDate, end:endDate}}
        title="Exercise Counts" subtitle="how many times an exercise was performed by a user" />
    )
}

export default ExerciseCounts;
