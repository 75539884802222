import React from 'react';
import useDataLoader from 'hooks/useDataLoader';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import app from 'app'


const baseUrl = "status-indicator/"

type StatusData = { 
    env: string
    server: string,
    status: "monitoring-test-passed"|"monitoring-test-failed",
    time: string
}

function GreenDotSvgIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#009000" className="bi bi-circle-fill" viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="8"/>
        </svg>
    )
}
function RedDotSvgIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF0000" className="bi bi-circle-fill" viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="8"/>
        </svg>
    )
}




function  Indicator(props:StatusData) {

    const title = `Server: ${props.env}\nStatus: ${props.status}\nTime: ${props.time}`


    return      ( <Tooltip title={title}>
        <IconButton onClick={()=>{app.navigate("/monitoring")}}>
         {props.status === "monitoring-test-passed" ? <GreenDotSvgIcon /> : <RedDotSvgIcon />}
        </IconButton>
      </Tooltip>)

}

type StatusIndicatorProps = {
}

function  StatusIndicator(props:StatusIndicatorProps){
    const {loading, data, error} = useDataLoader(baseUrl)

    if (loading) return null;
    if (error) return null;

    return <><Indicator {...data.production} /> 
             <Indicator {...data.uk} /> 
             <Indicator {...data.ga} /> 
             <Indicator {...data.sandbox} />   </>

}   

export default StatusIndicator;
