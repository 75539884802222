import {TMenuItem} from "./types";
import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import React from "react";
//import Router from "../../router/Router";
import app from 'app'

type Props = {
  item:TMenuItem
  current: string
  collapsed?: boolean
  onChange?: (selected:string)=>void
}
export default ({item, current, collapsed, onChange}:Props)=>{
  return item ? (
    <ListItemButton
        selected={ current===item.title}
        onClick={()=>{
          if(item.link) {
           app.navigate(item.link)
          } else if(onChange){
            onChange(item.title)
          }
        }} >
      <ListItemIcon>{ item.icon }</ListItemIcon>
      {!collapsed && <ListItemText primary={item.title} /> }
    </ListItemButton>
  ) : null
}