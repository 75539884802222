import React,{useState} from 'react';
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent} from "@mui/material";

function getStyles(name: string, selected: string[]) {
    return {
      fontWeight:  selected.includes(name) ? 'bold' : 'normal',
    };
}


type MultipleSelectProps = {
    allOptions: string[];
    defaultOptions: string[];
    onChange: (selected: string[]) => void;
}

function MultipleSelect({allOptions,defaultOptions,onChange}:MultipleSelectProps) {
    const [partners, setPartners] = useState<string[]>(defaultOptions);

    const handleChange = (event: SelectChangeEvent<string>) => {
      const {
        target: { value },
      } = event;

      const seleted = typeof value === 'string' ? value.split(',') : value
      setPartners(seleted);
      onChange(seleted);
    };
  
    return (
      <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Parners</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={partners as unknown as string}
            onChange={handleChange}
            input={<OutlinedInput label="Name" />}
          >
            {allOptions.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, partners)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
}


export default MultipleSelect;