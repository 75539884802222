import useDataLoader from 'hooks/useDataLoader';
import React from 'react';
//import DataComponent from './DataComponent';


function ptime(t:number) {
    const s = Math.round(t)
    if (s < 60) {
        return `${s} sec.`
    } 
    const m = Math.round(s/60)
    if (m < 60) {
        return `${m} min.`
    } 
    const h = Math.round(m/60)
    return `${h} hours`
}

/*
class DbStatus extends DataComponent {
    _url = "hellodb"

    render() {
        if (!this.data) {
            return null;
        }

        const last_refresh = ptime(this.data.last_refresh)
        const last_log     = ptime(this.data.last_log)

        //print(last_refresh)
        return <div> 
                     Last refresh {last_refresh} ago<br/>
                     Last log {last_log} ago
               </div>
    }
}
*/

function DbStatus() {
    const url = "hellodb"
    const {loading, data, error} = useDataLoader(url)

    if (!data) {
        return null;
    }

    const last_refresh = ptime(data.last_refresh)
    const last_log     = ptime(data.last_log)

    //print(last_refresh)
    return <div> 
                 Last refresh {last_refresh} ago<br/>
                 Last log {last_log} ago
           </div>
}


export default DbStatus;
