import React from 'react';
import DataTableCard from 'components/DataTableCard';


type CountryTableProps = {
    partner : string
}

function CountryTable(props:CountryTableProps) {
    return (
        <DataTableCard url="country-table/" partner={props.partner} title="Users by Country" subtitle="" />
    )
}

export default CountryTable;
