import React from 'react';
import DataTableCard from 'components/DataTableCard';


type IlRecordingStatsProps = {
}

export function IlRecordingStats(props:IlRecordingStatsProps) {
    return (
        <DataTableCard url="il-recording-stats/?"  title="Manpower Recording Stats" subtitle="recording time (hours)" />
    )
}

export function IlRecordingStatsDaily(props:IlRecordingStatsProps) {
    return (
        <DataTableCard url="il-recording-stats-daily/?"  title="Daily Manpower Recording Stats" subtitle="recording time (minutes, last 15 days)" />
    )
}


