import React from 'react';
import DataTable from 'react-data-table-component';
import DataComponent from 'components/DataComponent';
//import Link from 'router/Link';
import {col,fieldSort,session} from 'utils/colutils'

type Props = {
    puser : string
}

function countGoal(row: any) {
    const countGoal = row.countGoal
    return countGoal === "None" ? "-" : countGoal
}


class UsersSessions extends DataComponent<Props> {
    baseUrl: string = "data/p/ex_history"

    get url() {
        return `${this.baseUrl}?cid=${this.props.puser}`
    }
    
    
    _render() {

        return (
            <DataTable 
                data={this.data.data} 
                striped highlightOnHover fixedHeader 
                columns={[
                    {name: 'session', selector: row=>session(row), sortFunction:fieldSort("session"), sortable: true, wrap:true},
                    col("time"),
                    col("name"),

                    col("side"),

                    {name: 'countGoal',    selector: countGoal, sortable: true, wrap:true},

                    col("duration"),

                    col("score"),
                    col("os"),
                    col("browser"),
                    col("gpu"),
                    col("min_fps"),
                    col("max_fps"),
                    col("models"),

                ]} 
            />
        );
    }

}


export default UsersSessions;
