import React, { ReactElement,ReactChildren,ReactNode } from 'react';
import MainContainer from "components/MainContainer";
import HeaderAppBar from 'components/HeaderAppBar';
import { TMenu } from './SideMenu/types';


type PageProps = {
    partner?: string,
    current?: string,
    children?: ReactChildren | ReactNode | ReactElement,
    menu?:TMenu,
    menuCollapsed?:boolean
}


export function RedBox(){
    return (
        <div style={{backgroundColor:"red", width:"250px",height:"250px"}}></div>
    )
}

type BlueBoxProps= {
    text?:string
}
export function BlueBox(props:BlueBoxProps){
    return (
        <div style={{backgroundColor:"blue", width:"250px",height:"250px"}}>{props.text}</div>
    )
}

  
  

function Page(props:PageProps) {
    return (
        <>
            <HeaderAppBar partner={props.partner} />
            <MainContainer menu={props.menu} menuCollapsed={props.menuCollapsed} current={props.current}>
                {props.children}
            </MainContainer>
        </>
    )
}



export default Page;