import React from 'react';
import Chart from 'react-apexcharts'
import PageCard from './PageCard';
import { partner2Url,list } from 'utils/mp';
import useDataLoader from "hooks/useDataLoader";
import Loader from "components/Loader"

type VisitsGraphProps = {
  partner: string|string[],
  kemtapi: boolean
}

/*
class VisitsGraph extends DataComponent<VisitsGraphProps> {
  baseUrl = "data/p/visit_days_hist"

  get url() {
    return `${this.baseUrl}?${partner2Url(this.props.partner)}${this.props.kemtapi ? "&kemtapi=1" : ""}`
  }

  title() {
    return null
    //return (<h3># unique users per day (last 90 days): </h3>)
  }



  _render() {
    const t = list(this.props.partner).join(",")
    return (
        <PageCard title="Unique users per day" subtitle={"last 90 days "+ t}>
          <Chart
            options={{
              xaxis: { categories: this.data.date },
              plotOptions: { hideOverflowingLabels: false },
            }}
            series={[
              { name: "visits", data: this.data.visits },
              { name: "camera access", data: this.data.camera_allowed },
              { name: "start an exercise", data: this.data.ex_start },
              { name: "finished an exercise", data: this.data.wo_users },
            ]}
            type="line"
            width="100%" height={450}
          />
        </PageCard>
    );
  }
}
export default VisitsGraph;



*/  
export default (props:VisitsGraphProps)=>{
    const {partner, kemtapi} = props
    const url = `data/p/visit_days_hist?${partner2Url(partner)}${kemtapi ? "&kemtapi=1" : ""}`
    const {loading, data, error} = useDataLoader(url)
    if (!data) {
        return <Loader/>
    }
    const t = list(partner).join(",")
    return (
        <PageCard title="Unique users per day" subtitle={`last 90 days | ${t}`} xlurl={url+"&xl=1"}>
          <Chart
            options={{
              xaxis: { categories: data.date },
              //plotOptions: { hideOverflowingLabels: false },
            }}
            series={[
              { name: "visits", data: data.visits },
              { name: "camera access", data: data.camera_allowed },
              { name: "start an exercise", data: data.ex_start },
              { name: "finished an exercise", data: data.wo_users },
            ]}
            type="line"
            width="100%" height={450}
          />
        </PageCard>
    );
}

