import React from 'react';
import Chart from 'react-apexcharts'
import useDataLoader from "hooks/useDataLoader";
import { paramsToUrl, UrlParamsT } from "../../utils"
import { Card, CardContent, Box } from "@mui/material";
import Loader from "../Loader";
import ErrorAlert from "../ErrorAlert";
import { partner2Url } from 'utils/mp';
import ExcelIcon from 'components/ExcelIcon';


type Props = {
  partner : string|string[]
  url   : string
  title?: string
  subtitle?: string
  xName : string
  yName : string
  params?: UrlParamsT
}

function BarPlotCard(props:Props) {
  const {url, partner, params, title, subtitle, xName, yName} = props
  const dataUrl = `data/p/${url}?${partner2Url(partner)}${paramsToUrl(params)}`
  const {loading, data, error} = useDataLoader(dataUrl)

  let subtitle_ = subtitle
  if (!subtitle) {
    if (params?.start) {
        subtitle_ = ` from ${params.start} `
    }
    if (params?.end) {
      subtitle_ += ` to ${params?.end} `
    }
  }

  return (
    <Card className="page-content-card">
      <CardContent>
        { title && <h4 className="page-content-card-title">{ title }{ subtitle_ ? <span>{ subtitle_ }</span> : null}
        <ExcelIcon url={dataUrl+"&xl=1"} filename={title}/> 
        </h4> }
        <Box sx={{height:'100%',position:'relative',flexGrow:1}}>
        { loading
          ? <Loader />
          : error
            ? <ErrorAlert error={ error } />
            : <Chart
                    options={{
                      xaxis: { categories: data[xName] },
                      //plotOptions:{ hideOverflowingLabels:false }
                    }}
                    series ={[{name: "count", data: data[yName]}]}
                    type="bar" width="100%" height={350} />
        }
        </Box>
      </CardContent>
    </Card>
  )
}

export default BarPlotCard;