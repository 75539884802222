import React from 'react';
import Funnel from 'components/Funnel';

type Props = {
}



class QuestionnaireFunnel extends React.Component<Props> {

  render() {  
    

    return (
      <>
        <Funnel partner="dummy" type="questionnaire" />
      </>
    );
  }

}


export default QuestionnaireFunnel;
