import React from 'react';
import app from 'app'
import {ip_address, col,duration, session} from 'utils/colutils'
import Link from 'router/Link';
import DataTableCard from "components/DataTableCard";
import {Box, TextField, FormControlLabel, Checkbox, Button} from "@mui/material";
import { MainMenu } from 'components/SideMenu/menus';
import Page from "components/Page";

export interface SearchQuery {
    name? : string
    workout_id? : string
    coach? : string
    partner? : string
    email? : string
    from? : string
    to? : string
    no_dev? : boolean
}

export
class WorkoutsSearchForm extends React.Component<SearchQuery> {
    nameRef: HTMLInputElement | null = null;
    idRef: HTMLInputElement | null = null;
    coachRef: HTMLInputElement | null = null;
    emailRef: HTMLInputElement | null = null;
    fromRef: HTMLInputElement | null = null;
    toRef: HTMLInputElement | null = null;
    noDev: HTMLInputElement | null = null;
    partnerRef: HTMLInputElement | null = null;

    search = () => {
            console.log("***",this.noDev?.checked)
            app.navigate("workouts",{
                    name : this.nameRef?.value, 
                    workout_id : this.idRef?.value, 
                    coach : this.coachRef?.value,
                    email : this.emailRef?.value,
                    from : this.fromRef?.value,
                    partner : this.partnerRef?.value,
                    to : this.toRef?.value,
                    no_dev: this.noDev?.checked,
            })
    }

    onKeyDown = (event:React.KeyboardEvent) => {
        if (event.key==="Enter"){
            this.search()
        }
    }

    render() {
      return (
        <Box justifyContent='flex-end' alignItems='flex-start' sx={{display:'flex',gap:'4px',flexWrap:'wrap'}}>
          <TextField inputRef={ref=>this.nameRef=ref} defaultValue={this.props.name} onKeyDown={this.onKeyDown} autoComplete='off' label="Workout Name" variant="outlined" size="small" sx={{maxWidth:160}} />
          <TextField inputRef={ref=>this.idRef=ref} defaultValue={this.props.workout_id} onKeyDown={this.onKeyDown} autoComplete='off' label="Workout ID" variant="outlined" size="small" sx={{maxWidth:110}} />
          <TextField inputRef={ref=>this.coachRef=ref} defaultValue={this.props.coach} onKeyDown={this.onKeyDown} autoComplete='off' label="Coach" variant="outlined" size="small" sx={{maxWidth:160}} />
          <TextField inputRef={ref=>this.partnerRef=ref} defaultValue={this.props.partner} onKeyDown={this.onKeyDown} autoComplete='off' label="Partner" variant="outlined" size="small" sx={{maxWidth:160}} />
          <TextField inputRef={ref=>this.emailRef=ref} defaultValue={this.props.email} onKeyDown={this.onKeyDown} autoComplete='off' label="Email" variant="outlined" size="small" sx={{maxWidth:160}} />
          <TextField inputRef={ref=>this.fromRef=ref} defaultValue={this.props.from} onKeyDown={this.onKeyDown} autoComplete='off' label="From" helperText={'YYYY-MM-DD'} sx={{maxWidth:120}} variant="outlined" size="small" />
          <TextField inputRef={ref=>this.toRef=ref} defaultValue={this.props.to} onKeyDown={this.onKeyDown} autoComplete='off' label="To" helperText={'YYYY-MM-DD'} sx={{maxWidth:120}} variant="outlined" size="small" />
          <FormControlLabel control={<Checkbox inputRef={ref=>this.noDev=ref} defaultChecked={this.props.no_dev} />} label="Hide dev sessions" sx={{maxWidth:120}} />
          <Button onClick={this.search} variant={'contained'} disableElevation sx={{marginTop:'5px'}}>FIND</Button>
        </Box>
      )
    }
}


const COLUMNS = [
    col("name",180),
    col("workout_id",80),
    //col("coach"),
    {name: '# exercises', selector: 'n_sides', sortable: true, width:"90px"},
    //{name: '# sets', selector: 'n_sets', sortable: true, width:"90px"},
    //{name: 'sets', selector: 'sets', sortable: true, width:"90px"},



    {name: '# started ex.', selector: 'exercise_started', sortable: true, width:"90px"},
    {name: 'duration', selector: duration, sortable: true, width:"80px"},

    {name: 'Y', width:"50px",
       selector: (row:any)=>{
        return row.models ? <Link to={`/story/${row.session}`}>Y</Link> :""
    } },
    {name: 'email', selector: 'user_email', sortable: true, wrap:true,width:"150px"},

    col("partner"),

    {name: 'user',    selector: 'cid', sortable: true, wrap:true},
    {name: 'session', selector: (row:any)=>session(row), sortable: true, wrap:true},
    {name: 'user_id', selector: 'user_id', sortable: true, wrap:true},
    {name: 'ip_address', selector: ip_address, sortable: true, wrap:true},

   // col("os",80),
   // col("browser"),
    //{name: 'gpu', selector: 'gpu', sortable: true, wrap:true},

    //col("browser_app"),
    //col("browser_version"),
   // col("country",80),
    col("version",80),


    {name: 'stared', selector: 'started1', sortable: true, wrap:true},
    {name: 'ended', selector: 'ended1', sortable: true, wrap:true},

    //{name: 'Mobile',     selector: 'mob', sortable: true, wrap:true},
    //col("models"),
    //{name: 'fps', selector: fps, sortable: true, wrap:true},
    //{name: 'url', selector: url, sortable: true, wrap:true},
    //{name: 'referrer', selector: referrer, sortable: true, wrap:true},
    //col("app"),

]

const WorkoutsSearchResults=({name,workout_id,coach,email,from,to,no_dev,partner}:SearchQuery)=> {

    const url = `workouts?x${name ? `&name=${name}` : ``}`+
          `${workout_id ? `&workout_id=${workout_id}` : ``}`+
          `${coach ? `&coach=${coach}` : ``}`+
          `${email ? `&email=${email}` : ``}`+
          `${from ? `&from=${from}` : ``}`+
          `${partner ? `&partner=${partner}` : ``}`+
          `${to ? `&to=${to}` : ``}`+
          `${no_dev ? `&no_dev=1` : ``}`

    return (
      <DataTableCard url={ url } columns={COLUMNS} height='full' heightShift={200} />
    )
}

export 
function Workouts(props:SearchQuery) {
    const propsx = {...props}
    if (propsx.no_dev===undefined){
        propsx.no_dev=true
    }
    //console.log(props)
    return <div>
      <Page menu={MainMenu} current="Workouts">
        <Box justifyContent='space-between' alignItems="baseline" mb={2} sx={{display:'flex'}}>
          <h3 className="page-title">Workouts</h3>
          <WorkoutsSearchForm    {...propsx} />
        </Box>
        <WorkoutsSearchResults {...propsx} />
      </Page>
    </div>
}



export default Workouts;