import React from "react";
import api from "app/api";

type ExcelIconProps = {
    url: string
    filename?: string
  }
export default  function ExcelIcon({url,filename}:ExcelIconProps) {
      console.log(">>>",url)
      return <a><img src="/images/excel.png" style={{width:'20px',height:'20px'}} onClick={()=>api.download(url,filename)}/></a>
  }