import React from 'react';
import DataComponent from 'components/DataComponent';
import Chart from 'react-apexcharts'
import PageCard from 'components/PageCard';
import ExcelIcon from 'components/ExcelIcon';


type ExercisesPerWorkoutProps = {
    partner : string
}

/* v2 */

class ExercisesPerWorkout extends DataComponent<ExercisesPerWorkoutProps> {
  baseUrl = "exercises-per-workout/"

  get url() {
    return `${this.baseUrl}?partner=${this.props.partner}`
  }

  _render() {
    return (
      <PageCard title="Histogram of # exercises per workout" subtitle="" xlurl={this.url+"&xl=1"}>
          <Chart
            options={ {
              xaxis: { categories: this.data.exercises },
              //plotOptions: { hideOverflowingLabels: false },
            } }
            series={ [

                

                      { name: "users", data: this.data.users },

                

            ] }
            type="bar"
            width="100%" height={450}
          />
      </PageCard>
    );
  }
}


export default ExercisesPerWorkout;
