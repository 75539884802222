import {useState} from "react";
import clsx from "clsx";
import {Divider, Drawer, IconButton, List, Toolbar} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {TMenu, TMenuItem} from "./types";
import SideMenuItem from "./SideMenuItem";
import SideMenuSubmenu from "./SideMenuSubmenu";

import "./style.scss"


type Props = {
  items?:TMenuItem[]
  current:string
  menu?:TMenu
  onChange?:(selected:string)=>void,
  collapsed?:boolean
}

export default ({current, onChange, menu, collapsed}:Props)=>{
  const [menuItems, setMenuItems] = useState<TMenuItem[]>(menu||[])
  const [sideCollapsed, setSideCollapsed] = useState(Boolean(collapsed))

  const toggleSideMenu = ()=>{
    setSideCollapsed(prevState => !prevState)
  }

  
  return (
    <Drawer variant="permanent" open className={clsx("page-sidebar", sideCollapsed && "sidebar-collapsed")}>
      <List component="nav" sx={{flexGrow:1}}>
        { menuItems.map((item, idx)=>{
            return  item !== null
                    ? item?.submenu
                      ? <SideMenuSubmenu key={idx} item={ item } current={ current } collapsed={sideCollapsed} onChange={onChange}/>
                      : <SideMenuItem key={idx} item={ item } current={ current } collapsed={sideCollapsed} onChange={onChange}/>
                        : <Divider key={idx} />
            })
        }
      </List>
      <Divider />
      <Toolbar>
        <IconButton onClick={toggleSideMenu}>
          { sideCollapsed ? <MenuIcon /> : <MenuOpenIcon /> }
        </IconButton>
      </Toolbar>
    </Drawer>
  )
}