import React from 'react';
import Chart from 'react-apexcharts'
import PageCard from './PageCard';
import { list, partner2Url } from 'utils/mp';
import useDataLoader from "hooks/useDataLoader";
import Loader from "components/Loader"

type NewUsersGraphProps = {
  partner: string|string[],
}

/*
class NewUsersGraph extends DataComponent<NewUsersGraphProps> {
  baseUrl = "data/p/newusers_days_hist"

  get url() {
    return `${this.baseUrl}?${partner2Url(this.props.partner)}`
  }
  title() {
    return null;
  }

  _render() {
    return (
        <PageCard title="First time Visitors, Exercisers" subtitle="last 90 days">
          <Chart
            options={{
              xaxis: { categories: this.data.date },
              plotOptions: { hideOverflowingLabels: false },
            }}
            series={[{ name: "# first time visits", data: this.data.visits },
            { name: "# first time exercises", data: this.data.exercises },
            ]}
            type="line"
            width="100%" height={450}
          />
        </PageCard>);
  }
}

export default NewUsersGraph

*/
export default (props:NewUsersGraphProps)=>{
    const {partner} = props
    const url = `data/p/newusers_days_hist?${partner2Url(partner)}`
    const {loading, data, error} = useDataLoader(url)
    if (loading) {
        return <Loader/>
    }
    const t = list(partner).join(",")

    return (
        <PageCard title="First time Visitors, Exercisers" subtitle={`last 90 days | ${t}`} xlurl={url+"&xl=1"}> 
          <Chart
            options={{
              xaxis: { categories: data.date },
              //plotOptions: { hideOverflowingLabels: false },
            }}
            series={[{ name: "# first time visits", data: data.visits },
            { name: "# first time exercises", data: data.exercises },
            ]}
            type="line"
            width="100%" height={450}
          />
        </PageCard>);


}

