import React from 'react';

export default function UnvalidatedEmail() {

    return (

            <div style={{ 
                    display: "flex", 
                    alignItems: "center",
                    justifyContent: "center", 
                    height:"100vh",
                    flexDirection: "column"
                  }}>
                

                  <h1>To access Kemtai Analytics please contact alex@kemtai.com </h1>

            </div>
    );

}


