//import PartnerPage from 'pages/PartnerPage';
import type {Router} from 'router/Router';
import Users         from 'pages/Users';
import UsersSessions from 'pages/UserSessions';
import { FunnelPage } from 'pages/PartnerPage/FunnelPage';
import { UserStatsPage } from 'pages/PartnerPage/UserStatsPage';
import { Adherence } from 'pages/PartnerPage/Adherence';
import {voriMenu} from  'components/SideMenu/menus';
  
export default function defineVoriRoutes(router:Router) {
    // router.route("vori","/", PartnerPage, { partner:"vori", 
    //                                         funnel_type:"kemtapi", 
    //                                         noMobileSessionsGraph:true, 
    //                                         noHomeLink:true,   
    //                                         noGPULink:true
    //                                       }
    //             )

    router.route("vori","/", FunnelPage, {menu: voriMenu, partner:"vori",   type:"kemtapi"}, {showURL:false})
    router.route("vori","/p/:partner/user-stats", UserStatsPage, { menu: voriMenu, partner:"vori", type:"kemtapi",noMobileSessionsGraph:true, noCountryTable:true}, {showURL:false})
    router.route("vori","/p/:partner/adherence", Adherence, { menu: voriMenu, partner:"vori",   type:"kemtapi", noMobileSessionsGraph:true}, {showURL:false})

    router.route("vori","/users/vori", Users , {menu: voriMenu, partner:"vori"}, {showURL:true})
    
    //router.route("vori","/users/vori/:puser", Users , {menu: voriMenu, partner:"vori", noGPULink:true}, {showURL:true})
    router.route("vori","/sessions/:puser", UsersSessions, {partner:"vori"}, {showURL:true})
}
