import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss"


export type  DateRangeFilterProps = {
    startDate: Date|undefined
    setStartDate: (date: Date) => void
    endDate: Date|undefined
    setEndDate: (date: Date) => void
}

export function DateRangeFilter({startDate, setStartDate, endDate, setEndDate}:DateRangeFilterProps){
    return (<div className="funnel-date-range-filter" >
                <div className="date-range-label">Date Range</div>

                <div className="date-range-control from">
                    <label>From</label>
                    <DatePicker dateFormat="yyyy/MM/dd" selected={startDate} onChange={(date: Date) => setStartDate(date)} />
                </div>
                <div className="date-range-control">
                    <label>To</label>
                    <DatePicker dateFormat="yyyy/MM/dd" selected={endDate} popperPlacement="bottom-end" onChange={(date: Date) => setEndDate(date)} />
                </div>
           </div>
    )
}
