import strftime from "strftime"

export type UrlParamsT = {
  [key:string]:string|undefined
}
export const paramsToUrl = (params:UrlParamsT|undefined)=>{
  let outStr=''
  if(params){
    for(let k in params){
      outStr += `&${k}=${params[k]}`
    }
  }
  return outStr
}



export function date2string(date:Date|undefined) {
  if (!date) return ""
  return strftime("%Y-%m-%d", date)
}