import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React from 'react';

import GlobalStyles from './globalStyles';
import { light } from './themes';


export const ThemeProvider: React.FC = ({ children }) => {
	const theme = createTheme(light);
	return (
			<MuiThemeProvider theme={theme}>
				<CssBaseline />
				<GlobalStyles />
				{children}
			</MuiThemeProvider>
	);
};
