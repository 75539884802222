import React from 'react';
import DataComponent from 'components/DataComponent';
import Chart from 'react-apexcharts'
import PageCard from 'components/PageCard';


type NewPatientsByDateProps = {
    partner : string
}


class NewPatientsByDate extends DataComponent<NewPatientsByDateProps> {
  baseUrl = "new-patients-by-date/"

  get url() {
    return `${this.baseUrl}?partner=${this.props.partner}`
  }

  _render() {
    return (
      <PageCard title="New patients by date" subtitle="last 60 days">
          <Chart
            options={ {
              xaxis: { categories: this.data.day },
              //plotOptions: { hideOverflowingLabels: false },
            } }
            series={ [

                

                      { name: "new_patients", data: this.data.new_patients },

                

            ] }
            type="bar"
            width="100%" height={450}
          />
      </PageCard>
    );
  }
}


export default NewPatientsByDate;
