import React from 'react';
import Page from 'components/Page';
import { PartnerPageMenu } from 'components/SideMenu/menus';

import {Grid, Box} from "@mui/material"
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import DataTableCard from 'components/DataTableCard';
import VisitsGraph from 'components/VisitsGraph';
import NewUsersGraph from 'components/NewUsersGraph';
import MobileSessionsGraph from "./MobileSessionsGraph";
import { TMenu } from 'components/SideMenu/types';
import NewPatientsByMonth from 'components/wg/NewPatientsByMonth';
import NewPatientsByDate from 'components/wg/NewPatientsByDate';
import CountryTable from 'components/wg/CountryTable';
import "./style.scss"
import MonthlyStats from 'components/wg/MonthlyStats';

type UserStatsPageProps = {
    partner : string
    noMobileSessionsGraph? : boolean
    noCountryTable? : boolean
    type? : string,
    menu? : TMenu
}

export function UserStatsPage(props:UserStatsPageProps) {
    return (
        <Page partner={props.partner} menu={props.menu || PartnerPageMenu(props.partner)} current="User Stats">
            <Box>
            <h3 className="page-title">{ <GroupOutlinedIcon /> }User Stats</h3>

            { props.partner === 'ro' && <>
                <Grid container mb={3}>
                    <Grid item xs={12} lg={4}>
                         <DataTableCard url="ro_first_ex_monthly_stats/" partner={props.partner} title="New patients by month" />
                    </Grid>
                </Grid>

                <NewPatientsByMonth partner={props.partner}/>
                <NewPatientsByDate  partner={props.partner}/>
                </>
            }

            <DataTableCard url="data/p/new_user_by_month" partner={props.partner} title="New Users By Month" 
            subtitle='couning users who finished at least one exercise' />

            <VisitsGraph partner={props.partner} kemtapi={props.type==="kemtapi"}/>

            <NewUsersGraph partner={props.partner}/>

            <MonthlyStats partner={props.partner}/>

            {!props.noMobileSessionsGraph &&
                <MobileSessionsGraph partner={props.partner}/>
            }
            {!props.noCountryTable &&
                <CountryTable partner={props.partner} />
            }
            <></>

            </Box>
        </Page>
    )
}

