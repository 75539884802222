
import app from 'app';


function Logout() {
    app.logout()       
    app.navigate("/login")
    return null
}

export default Logout;
