import {Avatar, Card, CardContent, CardMedia, CardActionArea} from "@mui/material";
//import Router from "../../../router/Router";
import app from 'app'
import {logo} from "../../../utils/logo";
import { T_PARTNER_LIST_ITEM } from "../partners"

import "./style.scss"


type Props = {
  partner:T_PARTNER_LIST_ITEM
  data: any
}

function PartnerCard(props:Props) {
  const {partner, data} = props
  const logoSize = 60

  return (
    <Card className="partner-card">
      <CardActionArea onClick={()=>app.navigate(`/p/${partner.code}/user-stats`)}>
      <CardContent >
        { logo(partner.code)
          ? <CardMedia image={logo(partner.code)} title={partner.name} sx={{ height:logoSize, backgroundSize:'contain' }} />
          : <Avatar variant="rounded" alt={partner.name} sx={{height:logoSize,width:logoSize}} />
        }
        <h4>{ partner.name }</h4>
        

        {data && data[partner.code] &&
           <table>
                <tbody>
                <tr><td><b>Exercises</b> last week:</td><th>{ data[partner.code].exercises_last_week }</th></tr>
                <tr><td><b>Active users</b> last 30 days:</td><th>{ data[partner.code].active_users }</th></tr>
                <tr><td><b>Total users</b>:</td><th>{ data[partner.code].users }</th></tr>
                </tbody>
           </table>
         }
      </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default PartnerCard;
