import {Keypoints, PointName } from "./keypoints"
//import assert from 'assert';
import _ from "lodash"

type NodeColorMap = Map<PointName,string>
type PointPair = [PointName,PointName]
type RGB        = [number,number,number]
type PairColor = [PointPair,string]

function col (v:string|RGB) {
    if (typeof(v) === "string"){
        return  v
    } else {
        return `rgb(${v[0]},${v[1]},${v[2]})`
    }
}


export interface ColorScheme {
    pointColor(p:PointName):string;
    lines():PairColor[];
    lineWidth:number;
    radius:number;
}

export class XColorScheme implements ColorScheme { 
    nodeColor :  NodeColorMap =  new Map(); 
    lineColor :  PairColor[] = []
    
    constructor(nodeColor:NodeColorMap = new Map(), 
                lineColor:PairColor[]=[], 
                public defaultColor = "white", 
                public lineWidth = 5, 
                public radius = 5) {
        this.nodeColor = nodeColor
        this.lineColor = [
            [['neck', 'right_shoulder'], defaultColor],
            [['neck', 'left_shoulder'], defaultColor],
            [['neck_back', 'right_shoulder'], defaultColor],
            [['neck_back', 'left_shoulder'], defaultColor],
            [['right_shoulder', 'right_elbow'], defaultColor],
            [['right_elbow', 'right_wrist'], defaultColor],
            [['left_shoulder', 'left_elbow'], defaultColor],
            [['left_elbow', 'left_wrist'], defaultColor],
            [['right_hip', 'right_knee'], defaultColor],
            [['right_knee', 'right_ankle'], defaultColor],
            [['left_hip', 'left_knee'], defaultColor],
            [['left_knee', 'left_ankle'], defaultColor],
            [['neck', 'chin'], defaultColor],
            [['chin', 'nose'], defaultColor],
            [['right_eye', 'right_ear'], defaultColor],
            [['left_eye', 'left_ear'], defaultColor],
            [['left_wrist', "left_thumb"],defaultColor],
            [['left_wrist', "left_finger"],defaultColor],
            [['right_wrist', "right_thumb"],defaultColor],
            [['right_wrist', "right_finger"],defaultColor],
            [['right_ankle', "right_bigtoe"],defaultColor],
            [['right_ankle', "right_heel"],defaultColor],
            [['left_ankle', "left_bigtoe"],defaultColor],
            [['left_ankle', "left_heel"],defaultColor],
            [["neck_back","spinal_cord_1"],defaultColor],
            [["neck","spinal_cord_1"],defaultColor],
            [["spinal_cord_1","spinal_cord_2"],defaultColor],
            [["spinal_cord_2","spinal_cord_3"],defaultColor],
            [["spinal_cord_3","spinal_cord_4"],defaultColor],
            [["spinal_cord_4","spinal_cord_5"],defaultColor],
            [["left_hip","spinal_cord_5"],defaultColor],
            [["right_hip","spinal_cord_5"],defaultColor],
        ]

    }

    pointColor(p:PointName) {
        return this.nodeColor.get(p) || this.defaultColor
    }
    
    lines(){
        return this.lineColor
    }
    
}

export const whiteColorScheme  = new XColorScheme()
export const greenColorScheme  = new XColorScheme(undefined,undefined,"green", 3)
export const blueColorScheme   = new XColorScheme(undefined,undefined,"cyan")


export class AnnColorScheme implements ColorScheme {
    nodeColor :  NodeColorMap; 
    defaultColor : string = "white"
    lineColor :  PairColor[] = []

    constructor(public lineWidth = 5, public radius = 5){
        const left   = col([0, 255, 0])
        const right  = col([0, 0, 255])
        const center = col([255,0,0])
        const heel   = col([255,120,120])
        const thumb  = col([255, 255, 0])
        const back_arr = col([5,0,130])
        const front_arr = col([70,130,180])
            
        this.nodeColor =  new Map<PointName,string>([
            ['nose',  center],               
            ['neck',  center],
            ["top_of_the_head", col([255, 85, 0])],
            ["chin", center],
            ['left_elbow',    left], ['right_elbow', right], 
            ['left_wrist',    left], ['right_wrist', right],
            ['left_shoulder', left], ['right_shoulder', right],
            ['left_hip', left],      ['right_hip', right],
            ['left_knee',left],      ['right_knee',  right],
            ['left_ankle', left] ,   ['right_ankle', right],
            ['left_eye', left],      ['right_eye',  right],
            ['left_ear', left],      ['right_ear', right],
            ["left_thumb",  left],   ["right_thumb",  right],
            ["left_finger", left],   ["right_finger", right],
              
            ["left_bigtoe", col([ 85, 0,255])],  
            ["right_bigtoe",col([ 85, 0,255])],
            ["left_heel",heel],     ["right_heel",heel],
            ["spinal_cord_1", center],
            ["spinal_cord_2", center],
            ["spinal_cord_3", center],
            ["spinal_cord_4", center],
            ["spinal_cord_5", center],
            ["neck_back", col([200,128,128])],
        ])

        this.lineColor = [
            [['neck', 'right_shoulder'], right],
            [['neck', 'left_shoulder'], left],
            [['neck_back', 'right_shoulder'], right],
            [['neck_back', 'left_shoulder'], left],

            [['right_shoulder', 'right_elbow'], right],
            [['right_elbow', 'right_wrist'], right],
            [['left_shoulder', 'left_elbow'], left],
            [['left_elbow', 'left_wrist'], left],
            [['right_hip', 'right_knee'], right],
            [['right_knee', 'right_ankle'], right],
            [['left_hip', 'left_knee'], left],
            [['left_knee', 'left_ankle'], left],

            [['neck', 'chin'], center],
            [['chin', 'nose'], center],
            //[['nose', 'right_eye'], right],
            [['right_eye', 'right_ear'], right],
            //[['nose', 'left_eye'], left],
            [['left_eye', 'left_ear'], left],
        
            [['left_wrist', "left_thumb"],thumb],
            [['left_wrist', "left_finger"],left],
            [['right_wrist', "right_thumb"],thumb],
            [['right_wrist', "right_finger"],right],
        
            [['right_ankle', "right_bigtoe"],col([ 85, 0,255])],
            [['right_ankle', "right_heel"],heel],
            [['left_ankle', "left_bigtoe"],col([ 85, 0,255])],
            [['left_ankle', "left_heel"],heel],
        
            [["neck_back","spinal_cord_1"],center],
            [["neck","spinal_cord_1"],center],
            [["spinal_cord_1","spinal_cord_2"],center],
            [["spinal_cord_2","spinal_cord_3"],center],
            [["spinal_cord_3","spinal_cord_4"],center],
            [["spinal_cord_4","spinal_cord_5"],center],
            
            /*
            [["neck_back","spinal_cord_1"],center],
            [["neck","spinal_cord_1"],center],
            [["spinal_cord_1","spinal_cord_2"],center],
            [["spinal_cord_2","spinal_cord_3"],center],
            [["spinal_cord_3","spinal_cord_4"],center],
            [["spinal_cord_4","spinal_cord_5"],center],
            */

            [["neck",   "front_1"],front_arr],
            [["front_1","front_2"],front_arr],
            [["front_2","front_3"],front_arr],
            [["front_3","front_4"],front_arr],
            [["front_4","front_5"],front_arr],
          
          
            [["neck_back",   "back_1"],back_arr],
            [["back_1","back_2"],back_arr],
            [["back_2","back_3"],back_arr],
            [["back_3","back_4"],back_arr],
            [["back_4","back_5"],back_arr],
          

            [["left_hip","spinal_cord_5"],left],
            [["right_hip","spinal_cord_5"],right],
        ]
    }

    pointColor(p:PointName) {
        return this.nodeColor.get(p) || this.defaultColor
    }
    
    lines(){
        return this.lineColor
    }
    

}

export const annColorScheme = new AnnColorScheme()


export function drawSkeleton(ctx:CanvasRenderingContext2D, kp:Keypoints, col : ColorScheme, lines=true, dx=0, dy=0, f=1, 
                             subset:PointName[]|null=null)
{
    if (kp == null) {
        return
    }

    ctx.lineWidth = col.lineWidth;

    for (let point of kp.points()) {  

        if (subset && _.indexOf(subset,point) < 0) {
            continue
        }

        let p = kp.get(point)
        //assert(p)
        let {x,y} = p!.scale(dx, dy, f)
        ctx.beginPath();
        ctx.arc(x, y, col.radius , 0, 2 * Math.PI, false);
        //ctx.strokeStyle = col.pointColor(point);
        //ctx.stroke();
        ctx.fillStyle = col.pointColor(point);
        ctx.fill();

    }
    if (!lines) {
        return
    }
    for (let [pair,c] of col.lines()) {
        let [p1,p2] = pair
        let pp1 = kp.get(p1)
        let pp2 = kp.get(p2)
        if (pp1 && pp2) {             
            if (subset) {
                if (_.indexOf(subset,p1) < 0) {
                    continue
                }
                if (_.indexOf(subset,p2) < 0) {
                    continue
                }
            }
        

            let {x:x1,y:y1} = pp1.scale(dx, dy, f)
            let {x:x2,y:y2} = pp2.scale(dx, dy, f)

            ctx.beginPath();
            ctx.strokeStyle = c;  
            ctx.moveTo(x1, y1);
            ctx.lineTo(x2,y2);
            ctx.stroke(); 
        }
    }
    
}



export default drawSkeleton
