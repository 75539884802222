
import app, {AppController} from './app'

import React from "react";



class App extends React.Component<{},any> {

    app : AppController = app

    constructor(props:any) {
        super(props)
        this.app.init()
    }

    componentDidMount(): void {
        this.app.setSetState((state:any)=>{this.setState(state)})
    }

    render() {
        return this.app.Component
    }

}

export {App};
