import React from "react";
import {Box, Card, CardContent} from "@mui/material";
import ExcelIcon from "components/ExcelIcon";

import "./style.scss"

type Props = {
  title?:string
  subtitle?:string
  xlurl?:string
}
const PageCard:React.FC<Props> = ({children, title, subtitle, ...props})=>{

  return (
    <Card className="page-content-card" {...props}>
      <CardContent>
        { title && <h4 className="page-content-card-title">{ title }{ subtitle ? <span>{ subtitle }</span> : null}
        { props.xlurl && <ExcelIcon url={props.xlurl} filename={title}/> }
        </h4> }
        {children}
      </CardContent>
    </Card>
  )

}

export default PageCard